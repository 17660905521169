<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-3 gap-7 px-7 pt-7">
      <div class="col-span-2 mb-4 xl:mb-2">
        <div v-if="isEntitySaved" class="w-full p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
          {{ $t('common.documentSaved') }}
        </div>

        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-wrap justify-between items-center mb-4 p-7 border-b border-gray-200">
            <div v-if="userStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg mt-5 w-full" role="alert">
              {{ userStore.error }}
            </div>
            <div>
              <h1 class="text-3xl font-medium">{{ $t('users.edit') }}</h1>

              <p class="mb-2" v-if="checkRole('admin')">
                <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\User', id: userStore.user.id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
              </p>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden shadow">
                  <div v-if="userStore.user">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2 flex justify-between pb-4 border-b border-gray-200">
                            <div class="flex items-center">
                              <input-toggle-switch v-model="userStore.user.active" :label="$t('users.active')"/>
                            </div>
                            <div>
                              <label class="block mb-2 text-sm font-medium text-gray-900" for="file_input">{{ $t('users.role') }}</label>
                              <div class="flex items-center space-x-4">
                                <div v-for="(role, i) of userStore.user.roles" :key="role.id" class="flex items-center">
                                  <input-checkbox :v$="v$.roles" v-model="userStore.user.roles[i].attached" :id="role.id" :label="role.name"/>
                                </div>
                              </div>
                              <p v-if="v$.roles.$error" class="mt-2 text-sm text-red-600"> {{ v$.roles.$errors[0].$message }} </p>
                            </div>
                          </div>
                          <div class="sm:col-span-1">
                            <label class="block mb-2 font-medium text-basic-gray">{{ $t('common.contour') }}:</label>
                            <Multiselect
                                v-model="selectedContours"
                                :options="contours"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="name"
                                label="name"
                                :close-on-select="false"
                                :searchable="true" />
                            <label
                                @click="onHandleContourSelectedAll"
                                class="block mb-2 font-medium text-basic-gray">
                              {{ isSelectContoursAll ? 'Убрать все' : 'Выбрать все' }}
                            </label>
                          </div>
                          <div class="sm:col-span-2">
                            <label class="block mb-2 font-medium text-basic-gray">Участники:</label>
                            <Multiselect
                                v-model="selectedParticipant"
                                :options="participants"
                                :mode="'tags'"
                                :valueProp="'id'"
                                track-by="nameRu"
                                label="nameRu"
                                max="1"
                                :close-on-select="false"
                                :searchable="true" />
                          </div>
                          <div class="sm:col-span-2">
                            <input-text :v$="v$.name" v-model="userStore.user.fio" :label="$t('users.fio')"/>
                          </div>
                          <div class="sm:col-span-2">
                            <input-text :v$="v$.name" v-model="userStore.user.name" :label="$t('users.name')"/>
                          </div>
                          <div>
                            <input-text :v$="v$.email" v-model="userStore.user.email" :label="$t('users.email')"/>
                          </div>
                          <div>
                            <input-text :v$="v$.phone" v-model="userStore.user.phone" :label="$t('users.phone')"/>
                          </div>
                          <div class="sm:col-span-2" v-if="userStore.user.roles.find(item => item.key === 'exhibitor' && item.attached)">
                            <input-select-lite v-model="userStore.user.exhibitor_type_id" :options="exhibitorTypes" :label="$t('users.exhibitor_type_id')" :placeholder="' '"/>
                          </div>
                          <div class="sm:col-span-2" v-if="userStore.user.roles.find(item => item.key === 'manager' && item.attached)">
                            <label class="block mb-2 text-sm font-medium text-gray-900">{{ $t('users.access_exhibitor_type') }}</label>
                            <Multiselect v-model="userStore.user.access_exhibitor_type_ids" :options="exhibitorTypes" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                          </div>

                          <div class="sm:col-span-2" v-if="exhibitorTypes.find(item => item.id === parseInt(userStore.user.exhibitor_type_id) && item.is_federal)">
                            <label class="block mb-2 text-sm font-medium text-gray-900">{{ $t('users.federal_districts') }}</label>
                            <Multiselect v-model="userStore.user.federal_districts" :options="federalDistricts" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                          </div>

                          <div>
                            <input-text :v$="v$.password" v-model="userStore.user.password" :label="$t('users.password')"/>
                          </div>
                          <div>
                            <input-text :v$="v$.password_confirmation" v-model="userStore.user.password_confirmation" :label="$t('users.password_confirmation')"/>
                          </div>
                          <div class="sm:col-span-2">
                            <label for="description" class="block mb-2 text-sm font-medium text-gray-900">{{ $t('users.comment') }}</label>
                            <textarea v-model="userStore.user.comment" id="description" rows="2"
                                      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"></textarea>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="p-7 flex justify-end">
                      <button @click.prevent="saveUser" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">
                        <span>{{ $t('common.save') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useUserStore} from "../../stores/UserStore";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {email, helpers, maxLength, minLength, required, sameAs, selectInList} from '../../utilits/custom-validators.js'
import InputToggleSwitch from "../flowbite/form/inputToggleSwitch.vue";
import InputCheckbox from "../flowbite/form/inputCheckbox.vue";
import Multiselect from '@vueform/multiselect'
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import {checkRole} from "../../utilits/functions";
import {serviceContourList} from "../../service/lists/serviceListContour.js";
import {serviceSelectedUserContours} from "../../service/user/user.js";
import {serviceParticipantList} from "../../service/lists/serviceListParticipant.js";

const userStore = useUserStore();
userStore.error = null;

const loadData = ref(true);

const exhibitors = ref([])

const exhibitorTypes = ref([])
const federalDistricts = ref([])

const isEntitySaved = ref(false)

const contours = ref([]);
const selectedContours = ref([]);
const isSelectContoursAll = ref(false);
const participants = ref([]);
const selectedParticipant = ref([]);

// Первоначальные данные
const route = useRoute()
const router = useRouter()

// isExhibitor.value = !!userStore.roles?.find(item => item.attached === true);

serviceContourList().then(response => {
  contours.value = response?.data || [];
});

serviceParticipantList().then(response => {
  participants.value = response.data || [];
});

const onHandleContourSelectedAll = () => {
  isSelectContoursAll.value = !isSelectContoursAll.value;

  if (isSelectContoursAll.value)
    selectedContours.value = contours.value.map(item => item.id);

  if (!isSelectContoursAll.value)
    selectedContours.value = [];
}

serviceSelectedUserContours(route.params.id).then(response => {
  const data = response?.data || [];
  selectedContours.value = data.map(item => item.id);
});

userStore.getDirectories().then(() => {
  exhibitorTypes.value = [...exhibitorTypes.value, ...userStore.exhibitorTypes];
  federalDistricts.value = [...federalDistricts.value, ...userStore.federalDistricts];

  userStore.exhibitors.forEach(function (exhibitor) {
    exhibitors.value.push({
      value: exhibitor.id,
      name: exhibitor.name,
    })
  })

  if (route.params.id > 0) {
    userStore.getUserById(route.params.id).then(() => {
      loadData.value = false

      selectedParticipant.value = userStore.user?.participant?.id ? [userStore.user.participant.id] : [];
    })
  } else {
    userStore.setEmptyUser().then(() => {
      loadData.value = false

      if (exhibitorTypes.value.length === 1) {
        userStore.user.exhibitor_type_id = exhibitorTypes.value[0]?.id
      }

      if (federalDistricts.value.length === 1) {
        userStore.user.federal_districts = federalDistricts.value[0]?.id
      }
    })
  }
})

// Валидация
const rules = computed(() => ({
  email: {required, email},
  name: {required},
  phone: {required, minLength: minLength(10), maxLength: maxLength(11)},
  roles: {
    selectInList: helpers.withMessage('Нужно выбрать минимум одну роль', selectInList('attached'))
  },
  password: {minLength: minLength(9)},
  password_confirmation: {
    sameAsPassword: helpers.withMessage('Должно быть равно паролю', sameAs(state.password))
  },
}));

let state = reactive({
  email: '',
  name: '',
  phone: '',
  exhibitor_type_id: '',
  access_exhibitor_type_ids: [],
  roles: [],
  password: '',
  password_confirmation: '',
  selectedContours: [],
});

watch(userStore, (userStore) => {
  if (userStore.user) {
    state.email = userStore.user.email;
    state.name = userStore.user.name;
    state.phone = userStore.user.phone;
    state.exhibitor_type_id = userStore.user.exhibitor_type_id;
    state.roles = userStore.user.roles;
    state.access_exhibitor_type_ids = userStore.user.access_exhibitor_type_ids;
    state.password = userStore.user.password;
    state.password_confirmation = userStore.user.password_confirmation;
    state.selectedContours = userStore.user.selectedContours;
  }
});

const v$ = useVuelidate(rules, state)

// Работа с данными
const saveUser = () => {
  userStore.error = null;

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true;

    let isNew = userStore.user.id === undefined;
    userStore.user.selectedContours = selectedContours;

    userStore.user.participantId = selectedParticipant.value?.length ? selectedParticipant.value[0] : null

    userStore.storeUser(userStore.user).then(() => {
      if (!userStore.error && isNew) {
        router.push({name: 'UserEdit', params: {id: userStore.user.id}})
      }

      if (!userStore.error) {
        isEntitySaved.value = true

        setTimeout(() => {
          isEntitySaved.value = false
        }, 4000)
      }

      loadData.value = false;
    });

    v$.value.$reset();
  }
}
</script>
