<template>
  <spinner v-if="isLoadingData"/>
  <div v-if="!isLoadingData">

    <div
        v-if="saveMessageResponse"
        style="z-index: 100; top: 80px"
        id="toast-top-right"
        :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
        class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
        role="alert">
      <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
    </div>

    <modal
        v-if="isShowModalAchievementDelete"
        @closeDialog="isShowModalAchievementDelete=false"
    >
      <template #title>Удаление достижение</template>
      <template #body>
        <form action="#">
          <div class="grid gap-4 mb-5 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <label class="block mb-2 font-medium text-basic-gray">Вы точно хотите удалить данное достижение?</label>
            </div>
          </div>
          <div
              class="flex items-center space-x-4">
            <button @click.prevent="onHandleAchievementDelete" type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Да
            </button>
            <button @click="isShowModalAchievementDelete=false" type="button"
                    class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              {{ $t('common.cancel') }}
            </button>
          </div>
        </form>
      </template>
    </modal>

    <modal
        v-if="isShowModalSliderDelete"
        @closeDialog="isShowModalSliderDelete=false"
    >
      <template #title>Удаление слайда</template>
      <template #body>
        <form action="#">
          <div class="grid gap-4 mb-5 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <label class="block mb-2 font-medium text-basic-gray">Вы точно хотите удалить данный слайд?</label>
            </div>
          </div>
          <div
              class="flex items-center space-x-4">
            <button @click.prevent="onHandleSliderDelete" type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Да
            </button>
            <button @click="onHandleModalSliderClose" type="button"
                    class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              {{ $t('common.cancel') }}
            </button>
          </div>
        </form>
      </template>
    </modal>

    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="grid gap-4 mb-10 sm:grid-cols-3">
                        <div class="sm:col-span-2">
                          <div>
                            <div>
                              <span class="text-3xl font-medium mr-4">{{ nameRu }}</span>
                            </div>
                          </div>

                          <div class="mt-2" v-if="profileId">
                            <p style="color: #9b9b9b;">Участник: <span style="color: #000;">{{participant?.nameRu}}</span></p>
                            <p style="color: #9b9b9b;">Пользователь: <span style="color: #000;">{{userCreated?.name}}</span></p>
                          </div>
                        </div>

                        <div class="sm:col-span-1">
                          <div class="text-right text-sm">
                            <p class="mb-2"><span class="text-gray-500">{{ $t('document.created') }}:</span>
                              {{ moment(createdAt).format('DD.MM.YYYY') }}</p>
                            <p class="mb-2"><span class="text-gray-500">{{ $t('document.updated') }}:</span>
                              {{ moment(updatedAt).format('DD.MM.YYYY') }}</p>
                            <p class="mb-2" v-if="checkRole('admin')">
                              <router-link
                                  :to="{name: 'UserLog', query: {object: 'App\\Models\\Api\\V1\\ContentProfile', id: profileId.value}}"
                                  target="_blank"
                                  class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">
                                {{ $t('common.toLogs') }}
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                          style="display: flex; justify-content: space-around; align-items: center; text-align: center;"
                          class="tabs">
                        <div
                            :class="{'active': tabKey === 'main'}"
                            style="padding: 10px; width: 100%;"
                            @click="onHandleClickTabKey('main')"
                            class="tab">
                          Основное
                        </div>
                        <div
                            :class="{'active': tabKey === 'achievements'}"
                            style="padding: 10px; width: 100%;"
                            @click="onHandleClickTabKey('achievements')"
                            class="tab">
                          Достижения
                        </div>
                        <div
                            :class="{'active': tabKey === 'sliders'}"
                            style="padding: 10px; width: 100%;"
                            @click="onHandleClickTabKey('sliders')"
                            class="tab">
                          Слайдер {{sliders.length ? `(${sliders.length})` : ''}}
                        </div>
                      </div>

                      <div v-if="tabKey === 'sliders'">
                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageSliderEmpty" class="alert-message-error">
                          {{messageSliderEmpty}}
                        </div>
                        <div
                            v-for="(slider, index) in sliders"
                            :key="index"
                            class="achievement-content-item">
                          <div class="achievement-content-item-image mb-7">
                            <div
                                v-if="typeof slider.image !== 'string'"
                                class="grid gap-4 mb-4">
                              <div class="sm:col-span-2">
                                <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                <drop-file
                                    :disabled="!isNewsEdit"
                                    v-model="slider.image"
                                    :type="imageTypes"
                                    :single="true"
                                />
                                <p v-if="slider.imageError" class="mt-2 text-sm text-red-600"> {{ slider.imageError }} </p>
                              </div>
                            </div>
                            <div
                                v-else
                                class="image-content" style="text-align: center; width: 250px; margin: auto;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="slider.image"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="slider.image=null"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                          <hr/>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="slider.authorRu"
                                  label="Наименование автора изображения (RU)"/>
                              <p v-if="slider.authorRuError" class="mt-2 text-sm text-red-600"> {{ slider.authorRuError }} </p>
                            </div>
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="slider.authorEn"
                                  label="Наименование автора изображения (EN)"/>
                              <p v-if="slider.authorEnError" class="mt-2 text-sm text-red-600"> {{ slider.authorEnError }} </p>
                            </div>
                          </div>
                          <hr />
                          <button
                              :disabled="!isNewsEdit"
                              type="button"
                              @click="onHandleDeleteActionModelSlider(index, slider?.id)"
                              class="px-7 mr-5 mt-5 py-3 mb-5 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                            Удалить
                          </button>
                        </div>

                        <div
                            @click="onHandleClickSliderCreate"
                            class="session-add">
                          <div class="session-btn">
                            Добавить новый слайд
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'achievements'">
                        <div class="achievement-content">
                          <div
                              v-for="(achievement, index) in achievements"
                              :key="index"
                              class="achievement-content-item mb-10">
                            <div class="achievement-content-item-image mb-7">
                              <div
                                  v-if="typeof achievement.image !== 'string'"
                                  class="grid gap-4 mb-4">
                                <div class="sm:col-span-2">
                                  <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                  <drop-file
                                      :disabled="!isNewsEdit"
                                      v-model="achievement.image"
                                      :type="imageTypes"
                                      :single="true"
                                  />
                                  <p v-if="achievement.imageError" class="mt-2 text-sm text-red-600"> {{ achievement.imageError }} </p>
                                </div>
                              </div>
                              <div
                                  v-else
                                  class="image-content" style="text-align: center; width: 250px; margin: auto;">
                                <img
                                    style="width: 250px; height: auto; object-fit: cover"
                                    :src="achievement.image"
                                    alt="">
                                <button
                                    :disabled="!isNewsEdit"
                                    @click="achievement.image=null"
                                    class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                  Удалить
                                </button>
                              </div>
                            </div>
                            <hr/>
                            <div class="author-achievement mb-5 mt-5">
                              <div>
                                <input-text
                                    :disabled="!isNewsEdit"
                                    v-model="achievement.authorRu"
                                    label="Наименование автора изображения (RU) *"/>
                                <p v-if="achievement.authorRuError" class="mt-2 text-sm text-red-600"> {{ achievement.authorRuError }} </p>
                              </div>
                              <div>
                                <input-text
                                    :disabled="!isNewsEdit"
                                    v-model="achievement.authorEn"
                                    label="Наименование автора изображения (EN)"/>
                                <p v-if="achievement.authorEnError" class="mt-2 text-sm text-red-600"> {{ achievement.authorEnError }} </p>
                              </div>
                            </div>
                            <hr />
                            <div class="description-achievement mt-5">
                              <div class="sm:col-span-2">
                                <input-textarea
                                    :disabled="!isNewsEdit"
                                    class="placeholder-gray-100"
                                    placeholder="Краткое описание"
                                    v-model="achievement.descriptionRu"
                                    label="Краткое описание (RU) *"
                                />
                                <p v-if="achievement.descriptionRuError" class="mt-2 text-sm text-red-600">
                                  {{ achievement.descriptionRuError }} </p>
                              </div>
                              <div class="sm:col-span-2">
                                <input-textarea
                                    :disabled="!isNewsEdit"
                                    class="placeholder-gray-100"
                                    placeholder="Краткое описание"
                                    v-model="achievement.descriptionEn"
                                    label="Краткое описание (EN)"
                                />
                                <p v-if="achievement.descriptionEnError" class="mt-2 text-sm text-red-600">
                                  {{ achievement.descriptionEnError }} </p>
                              </div>
                            </div>
                            <hr />
                            <button
                                :disabled="!isNewsEdit"
                                type="button"
                                @click="onHandleDeleteAchievement(index, achievement?.id)"
                                class="px-7 mr-5 mt-5 mb-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                              Удалить
                            </button>
                          </div>

                          <div
                              v-if="isNewsEdit"
                              @click="onHandleClickAchievementCreate"
                              class="session-add">
                            <div class="session-btn">
                              Добавить новое достижение
                            </div>
                          </div>
                          <div
                              v-else
                              class="session-add">
                            <div class="session-btn">
                              Добавить новое достижение
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="tabKey === 'main'">
                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <div class="grid gap-4 mb-4 sm:grid-cols-2 mt-5" v-if="status">
                              <div class="sm:col-span-2">
                                <div
                                    class="inline-block px-4 py-1 rounded-xl mr-4 whitespace-nowrap opacity-100 cursor-default"
                                    :class="['bg-status-' + (status === 14 ? 6 : status)]">
                                  <span>{{ statusAction?.name }}</span>
                                </div>
                              </div>
                            </div>
                            <br />
                            <hr/>
                            <br />
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="nameRu"
                                  label="Наименование (RU) *"/>
                              <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
                            </div>
                            <div class="mt-5">
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="nameEn"
                                  :label="'Наименование (EN) ' + requiredValue"/>
                              <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
                            </div>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionRu"
                                label="Краткое описание (RU) *"
                            />
                            <p v-if="shortDescriptionRuError" class="mt-2 text-sm text-red-600"> {{ shortDescriptionRuError }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionEn"
                                :label="'Краткое описание (EN) ' + requiredValue"
                            />
                            <p v-if="shortDescriptionEnError" class="mt-2 text-sm text-red-600"> {{ shortDescriptionEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionRu"
                                label="Подробное описание (RU) *"
                            />
                            <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{ descriptionRuError }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionEn"
                                :label="'Подробное описание (EN) ' + requiredValue"
                            />
                            <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{ descriptionEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите павильон *</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedPavilions"
                              :options="pavilions"
                              :mode="'tags'"
                              :valueProp="'id'"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedPavilionError" class="mt-2 text-sm text-red-600">
                            {{ selectedPavilionError }}
                          </p>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="typeof previewImage !== 'string' || !previewImage?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить изображение логотипа/герба *</h2>
                            <drop-file
                                v-model="previewImage"
                                :type="previewImageTypes"
                                :single="true"
                            />
                            <p v-if="previewImageError" class="mt-2 text-sm text-red-600"> {{ previewImageError }} </p>
                          </div>
                          <div v-else>
                            <div class="image-content" style="text-align: center; width: 250px; margin: auto;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="previewImage"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="onHandleDeletePreviewImage"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="typeof video !== 'string' || !video?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить видео файл</h2>
                            <drop-file
                                v-model="video"
                                :type="videoTypes"
                                :single="true"
                            />
                            <p v-if="videoError" class="mt-2 text-sm text-red-600"> {{ videoError }} </p>
                          </div>
                          <div v-else class="mb-5">
                            <video
                                style="width: 100%"
                                controls width="250">
                              <source :src="video" type="video/webm" />

                              <source :src="video" type="video/mp4" />
                            </video>
                            <button
                                :disabled="!isNewsEdit"
                                @click="onHandleDeleteVideo"
                                class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                              Удалить
                            </button>
                          </div>
                        </div>
                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="authorVideoTitleNameRu"
                                label="Наименование автора видео (RU)"/>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="authorVideoTitleNameEn"
                                label="Наименование автора видео (EN)"/>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-4"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (RU)
                              </label>
                              <p
                                  id="helper-checkbox-text-4"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-5"
                                type="checkbox"
                                v-model="isActiveEn"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-5" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (EN)
                              </label>
                              <p
                                  id="helper-checkbox-text-5"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSliderEmpty"
                      class="alert-message-error">
                    {{messageSliderEmpty}}
                  </div>

                  <div
                      v-if="isErrorVal"
                      style="padding: 5px 15px; margin-top: 40px; color: red;"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      <ul style="list-style: initial; padding: 15px;">
                        <li v-if="nameRuError"><span style="font-weight: bold">Наименование (RU):</span> {{nameRuError}}</li>
                        <li v-if="nameEnError"><span style="font-weight: bold">Наименование (EN):</span> {{nameEnError}}</li>
                        <li v-if="shortDescriptionRuError"><span style="font-weight: bold">Краткое описание (RU):</span> {{shortDescriptionRuError}}</li>
                        <li v-if="shortDescriptionEnError"><span style="font-weight: bold">Краткое описание (EN):</span> {{shortDescriptionEnError}}</li>
                        <li v-if="descriptionRuError"><span style="font-weight: bold">Подробное описание (RU):</span> {{descriptionRuError}}</li>
                        <li v-if="descriptionEnError"><span style="font-weight: bold">Подробное описание (EN):</span> {{descriptionEnError}}</li>
                        <li v-if="selectedPavilionError"><span style="font-weight: bold">Павильоны:</span> {{selectedPavilionError}}</li>
                        <li v-if="imageError"><span style="font-weight: bold">Изображение логотипа/герба:</span> {{imageError}}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="p-7 flex justify-between">
                    <div>
                      <div class="flex flex-wrap">
                        <div
                            v-for="process in statusProcess"
                            class="flex items-center mb-4">
                          <label @click.prevent="onHandleClickUpdate(process.id)"
                                 :class="['bg-status-' + (process.id === 14 ? 6 : process.id)]"
                                 class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                            <span>{{ process.name }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-span-2 mb-4 xl:mb-2">
              <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
                <div class="flex justify-between items-center p-7 border-b border-gray-200">
                  <div class="text-3xl font-medium">
                    {{ $t('document.remarksTitle') }}
                  </div>
                </div>
                <div class="p-7 pt-4">
                  <div class="flex-1 justify-between flex flex-col h-[50vh]">
                    <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                      <div class="chat-message" v-for="(message, index) in messages">
                        <div class="flex items-end" :class="{'justify-end': !message.isAnswer}">
                          <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                            <div>
                              <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.isAnswer, 'bg-blue-600 text-white': !message.isAnswer}">
                                <div class="text-xs">
                                  {{ message.user.name }}
                                </div>
                                <div class="text-xs">
                                  {{ message.createdAt }}
                                </div>
                                <div class="text-sm">
                                  {{ message.name }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                      <div class="relative flex">
                          <textarea
                              :placeholder="$t('chat.write_message')"
                              v-model="messageData"
                              rows="5"
                              class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                          ></textarea>
                      </div>
                      <div v-if="checkRole(['admin', 'manager', 'content_manager'])" class="items-center mt-2">
                        <button type="button"
                                @click.prevent="onHandleClickSendMessage"
                                class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                          <template v-if="!loadDataMessage">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                          </template>
                          <template v-else>
                            <svg aria-hidden="true" class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </div>
</template>

<script setup>
import {checkRole} from "../../../../utilits/functions.js";
import Spinner from "../../../flowbite/spinner.vue";
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import Modal from "../../../flowbite/modal.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'
import {useRoute, useRouter} from 'vue-router';
import moment from "moment";
import {serviceParticipantCategoryList} from "../../../../service/lists/serviceListParticipantCategory.js";
import {servicePavilionList} from "../../../../service/lists/serviceListPavilion.js";
import {
  serviceContentProfileAchievementDelete,
  serviceContentProfileFindId,
  serviceContentProfileMessageCreate, serviceContentProfileSliderDelete,
  serviceContentProfileUpdate
} from "../../../../service/contents/news/serviceContentProfile.js";
import {serviceContentEventSliderDelete} from "../../../../service/contents/news/serviceContentEvent.js";

const route = useRoute();
const router = useRouter();

const isLoadingData = ref(true);
const saveMessageResponse = ref(null);
const messageData = ref(null);
const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const loadDataMessage = ref(false);

const isShowModalSliderDelete = ref(false);
const helperSliderId = ref(null);

const isShowModalAchievementDelete = ref(false);
const isNewsEdit = ref(false);
const profileId = ref(route.params?.id);
const status = ref(null);
const statusAction = ref(null);
const statusProcess = ref([]);
const createdAt = ref(null);
const updatedAt = ref(null);
const userCreated = ref(null);
const tabKey = ref('main');
const helperAchievementId = ref(null);

const nameRu = ref('');
const nameRuError = ref('');
const nameEn = ref('');
const nameEnError = ref('');
const shortDescriptionRu = ref('');
const shortDescriptionRuError = ref('');
const shortDescriptionEn = ref('');
const shortDescriptionEnError = ref('');
const descriptionRu = ref('');
const descriptionRuError = ref('');
const descriptionEn = ref('');
const descriptionEnError = ref('');
const authorImageTitleNameRu = ref('');
const authorImageTitleNameRuError = ref('');
const authorImageTitleNameEn = ref('');
const authorImageTitleNameEnError = ref('');
const participantCategories = ref([]);
const pavilions = ref([]);
const selectedPavilions = ref([]);
const selectedPavilionError = ref('');
const isActiveRu = ref(true);
const isActiveEn = ref(true);
const participant = ref(null);
const achievements = ref([]);
const sliders = ref([]);

const authorVideoTitleNameRu = ref('');
const authorVideoTitleNameEn = ref('');

const messageSliderEmpty = ref('');
const isErrorVal = ref(false);
// requiredValue - нужно для того, чтобы выставить (*) где обязательность заполнение для администрации
const requiredValue = checkRole(['admin', 'manager', 'content_manager']) ? '*' : '';

const messages = ref([]);

const previewImage = ref(null);
const previewImageError = ref('');
const previewImageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 1,
};

const image = ref(null);
const imageError = ref('');
const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const video = ref(null);
const videoError = ref('');
const videoTypes = {
  extensions: ["mp4"],
  max_filesize: 100,
};

const onHandleClickTabKey = (key) => {
  tabKey.value = key;
}

const writeResponseData = (data) => {
  status.value = data.status;

  statusAction.value = data.statusProcess;
  statusProcess.value = [];
  isNewsEdit.value = false;

  if (checkRole(['admin', 'manager'])) {
    statusProcess.value = data.statusProcess?.process?.org || [];
  }
  if (checkRole(['admin', 'content_manager'])) {
    if (status.value === 6)
      statusProcess.value.push({id: 14, name: 'Опубликовать'});
  }
  if (checkRole(['content_exhibitor'])) {
    statusProcess.value = data.statusProcess?.process?.exp || [];
  }

  if (checkRole(['admin', 'manager', 'content_manager'])) {
    isNewsEdit.value = true;
  } else if (checkRole(['content_exhibitor', 'exhibitor']) && (data.status === 1 || data.status === 2 || data.status === 7)) {
    isNewsEdit.value = true;
  }

  messageData.value = '';
  achievements.value = data.achievements || [];
  userCreated.value = data.user;
  nameRu.value = data.nameRu;
  nameEn.value = data.nameEn;
  shortDescriptionRu.value = data.shortDescriptionRu;
  shortDescriptionEn.value = data.shortDescriptionEn;
  descriptionRu.value = data.descriptionRu;
  descriptionEn.value = data.descriptionEn;
  authorImageTitleNameRu.value = data.authorRu;
  authorImageTitleNameEn.value = data.authorEn;
  previewImage.value = data.previewImage;
  image.value = data.image;
  selectedPavilions.value = data?.pavilions?.length ? data.pavilions?.map(item => item.id) : [];
  isActiveRu.value = data.isActiveRu;
  isActiveEn.value = data.isActiveEn;
  createdAt.value = data.createdAt;
  updatedAt.value = data.updatedAt;
  participant.value = data.participant;
  messages.value = data.messages || [];
  sliders.value = data.sliders || [];
  video.value = data.video;
  authorVideoTitleNameRu.value = data.authorVideoRu;
  authorVideoTitleNameEn.value = data.authorVideoEn.


  sliders.value.map(item => {
    item.imageError = '';
    item.authorRuError = '';
    item.authorEnError = '';
  });
}

const onHandleDeletePreviewImage = () => {
  previewImage.value = null;
}

if (profileId.value) {
  Promise.all([serviceParticipantCategoryList(), servicePavilionList(), serviceContentProfileFindId(profileId.value)])
      .then(([responseParticipantCategory, responsePavilion, responseContentProfile]) => {
        isLoadingData.value = false;

        participantCategories.value = responseParticipantCategory.data || [];

        pavilions.value = responsePavilion.data || [];

        const data = responseContentProfile.data;
        if (data) {
          writeResponseData(data);
        }
      })
      .catch(error => {
        console.error('Ошибка при выполнении запросов:', error);
      });
}

const onHandleClickUpdate = (newStatus) => {
  const isValExhibitor = validateExhibitor();

  let isValAdministrationAgreed = null;
  if (!checkRole(['content_exhibitor']) && (newStatus === 6 || newStatus === 14)) {
    isValAdministrationAgreed = validateAdministrationAgreed();
  }

  const isValAchievement = validateAchievement();
  const isValSlider = validateSlider();

  isErrorVal.value = isValExhibitor || isValAdministrationAgreed;

  if (isValExhibitor || isValAdministrationAgreed || isValSlider || isValAchievement)
    return null;

  const params = {
    status: newStatus,
    nameRu: nameRu.value,
    nameEn: nameEn.value,
    shortDescriptionRu: shortDescriptionRu.value,
    shortDescriptionEn: shortDescriptionEn.value,
    descriptionRu: descriptionRu.value,
    descriptionEn: descriptionEn.value,
    authorRu: authorImageTitleNameRu.value,
    authorEn: authorImageTitleNameEn.value,
    activeRu: isActiveRu.value,
    activeEn: isActiveEn.value,
    pavilions: selectedPavilions.value?.length ? selectedPavilions.value : [],
    previewImage: previewImage.value.length ? previewImage.value[0] : null,
    image: null,
    messages: messageData.value ? [messageData.value] : [],
    achievements: achievements.value,
    sliders: sliders.value,
    video: video.value?.length ? video.value[0] : null,
    authorVideoRu: authorVideoTitleNameRu.value,
    authorVideoEn: authorVideoTitleNameEn.value,
  };

  serviceContentProfileUpdate(profileId.value, params).then(response => {
    const status = response?.success;
    const message = status ? 'Успешно обновлено' : 'Ошибка, данные не были обновлены';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      saveMessageResponse.value = null;
    }, 3000);

    if (response.success) {
      const news = response.data;
      writeResponseData(news);
    } else {
      console.error('log');
    }
  });
}

const onHandleClickSendMessage = () => {
  loadDataMessage.value = true;
  serviceContentProfileMessageCreate(profileId.value, {message: messageData.value}).then(response => {
    loadDataMessage.value = false;

    if (response.success) {
      messages.value.push({
        name: messageData.value,
        createdAt: moment().format('DD.MM.YYYY HH:mm'),
        user,
        isAnswer: !!checkRole(['content-exhibitor']),
      });

      messageData.value = null;
    }
  });
}

const validateExhibitor = () => {
  let isError = false;

  const valueName = nameRu.value?.trim();
  nameRuError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameRuError.value)
    isError = true;

  const valueShortDescriptionRu = shortDescriptionRu.value?.trim();
  shortDescriptionRuError.value = valueShortDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionRuError.value)
    isError = true;

  const valueDescriptionRu = descriptionRu.value?.trim();
  descriptionRuError.value = valueDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionRuError.value)
    isError = true;

  selectedPavilionError.value = selectedPavilions.value.length ? '' : 'Вы не выбрали павильон';
  if (selectedPavilionError.value)
    isError = true;

  previewImageError.value = previewImage.value ? '' : 'Вы не выбрали файл';
  if (previewImageError.value)
    isError = true;

  return isError;
}

const validateAdministrationAgreed = () => {
  let isError = false;

  const valueName = nameEn.value?.trim();
  nameEnError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameEnError.value)
    isError = true;

  const valueShortDescriptionEn = shortDescriptionEn.value?.trim();
  shortDescriptionEnError.value = valueShortDescriptionEn?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionEnError.value)
    isError = true;

  const valueDescriptionEn = descriptionEn.value?.trim();
  descriptionEnError.value = valueDescriptionEn?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionEnError.value)
    isError = true;

  return isError;
}

const validateAchievement = () => {
  let isError = false;

  achievements.value.map(item => {
    let image = item.image;
    if (Array.isArray(image)) {
      item.image = item.image[0];
    }
  });

  achievements.value.map(item => {
    item.imageError = !item.image ? 'Вы не выбрали файл' : '';
    if (item.imageError)
      isError = true;

    item.authorRuError = !item.authorRu?.length ? 'Не должно быть пустым' : '';
    if (item.authorRuError)
      isError = true;

    item.authorEnError = !item.authorEn?.length ? 'Не должно быть пустым' : '';
    if (item.authorEnError)
      isError = true;

    item.descriptionRuError = !item.descriptionRu?.length ? 'Не должно быть пустым' : '';
    if (item.descriptionRuError)
      isError = true;

    item.descriptionEnError = !item.descriptionEn?.length ? 'Не должно быть пустым' : '';
    if (item.descriptionEnError)
      isError = true;
  });

  return isError;
}

const validateSlider = () => {
  let isError = false;

  sliders.value.map(item => {
    let image = item.image;
    if (Array.isArray(image)) {
      item.image = item.image[0];
    }
  });

  if (!sliders.value?.length) {
    messageSliderEmpty.value = 'Добавьте хотя-бы один слайд';
    return true;
  }
  else messageSliderEmpty.value = '';

  sliders.value?.map(slider => {
    slider.imageError = !slider.image ? 'Вы не выбрали изображение' : '';

    if (slider.imageError)
      isError = true;
  });

  return isError;
}

const onHandleClickAchievementCreate = () => {
  achievements.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
    descriptionRu: '',
    descriptionRuError: '',
    descriptionEn: '',
    descriptionEnError: '',
  });
}

const onHandleDeleteAchievement = (index, id) => {

  if (!id) {
    achievements.value.splice(index, 1);
  }
  else {
    helperAchievementId.value = id;
    isShowModalAchievementDelete.value = true;
  }

  return null;
}

const onHandleAchievementDelete = () => {
  if (!profileId.value || !helperAchievementId.value)
    return null;

  serviceContentProfileAchievementDelete(profileId.value, helperAchievementId.value).then(response => {
      if (response.code !== 200)
        return null;

      achievements.value = achievements.value.filter(item => item?.id !== helperAchievementId.value);

      helperAchievementId.value = null;
      isShowModalAchievementDelete.value = false;
  });
}

const onHandleClickSliderCreate = () => {
  sliders.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
  });
}

const onHandleSliderDelete = () => {
  if (!profileId.value || !helperSliderId.value)
    return null;

  serviceContentProfileSliderDelete(profileId.value, helperSliderId.value).then(response => {
    if (response.code !== 200)
      return null;

    sliders.value = sliders.value.filter(item => item?.id !== helperSliderId.value);

    helperSliderId.value = null;
    isShowModalSliderDelete.value = false;
  });
}

const onHandleDeleteActionModelSlider = (index, id) => {
  if (!id) {
    sliders.value.splice(index, 1);
  }
  else {
    helperSliderId.value = id;
    isShowModalSliderDelete.value = true;
  }
}

const onHandleModalSliderClose = () => {
  helperSliderId.value = null;
  isShowModalSliderDelete.value = false;
}

const onHandleDeleteVideo = () => {
  video.value = null;
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>
