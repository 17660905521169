import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useUserStore = defineStore("userStore", () => {
    const exhibitors = ref([]);
    const exhibitorTypes = ref([]);
    const federalDistricts = ref([]);
    const users = ref([]);
    const roles = ref([]);
    const user = ref(null);
    const error = ref(null);

    const getDirectories = async (params = null) => {
        await axios.get('/exhibitors/user/directories')
            .then((r) => {
                federalDistricts.value = r.data.data.federalDistricts;
                exhibitorTypes.value = r.data.data.exhibitorTypes;
                exhibitors.value = r.data.data.exhibitors;
                roles.value = r.data.data.roles;
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const getRoles = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/exhibitors/user/roles', config)
            .then((r) => {
                roles.value = r.data.data.roles;
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const getUsers = async (params = null) => {

        let config = {
            params: params,
        }

        await axios.get('/exhibitors/users', config)
            .then((r) => {
                users.value = r.data.data.users;
                roles.value = r.data.data.roles;
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const getUserById = async (id) => {
        await axios.get('/exhibitors/user/' + id)
            .then((r) => {
                user.value = r.data.data;
            }).catch((e) => {
                catchResponse(e)
            })
    };

    const storeUser = async (data, params) => {
        await axios.post('/exhibitors/user', data)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    user.value = r.data.data;
                    getUsers(params);
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                catchResponse(e)
            })
    };

    const deleteUsers = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/exhibitors/users', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    user.value = r.data.data;
                    getUsers();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const setEmptyUser = async () => {
        user.value = {
            active: false,
            fio: '',
            name: '',
            comment: '',
            email: '',
            phone: '',
            access_exhibitor_type_ids: [],
            exhibitor_type_id: '',
            roles: roles.value,
            selectedContours: [],
            isExhibitor: true,
            participantId: null,
        }
    };

    const resetUser = async () => {
        user.value = null;
    }

    const restoreUsers = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/exhibitors/users/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getUsers();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const importUsers = async (data) => {
        await axios.post('/exhibitors/user/import', data)
            .then((r) => {
                let response = r.data;

                if (!response.success) {
                    error.value = response.error.message
                }
            }).catch((e) => {
                catchResponse(e)
            })
    }

    const catchResponse = (e) => {
        if (e.response.status === 422) {
            let errorMessage = [];
            for (let key in e.response.data.errors) {
                errorMessage.push(e.response.data.errors[key][0]);
            }
            error.value = errorMessage.join(' ');
        } else {
            error.value = 'Ошибка сервера. Попробуйте позже';
        }
    }

    return {
        exhibitors,
        users,
        exhibitorTypes,
        federalDistricts,
        user,
        roles,
        error,
        setEmptyUser,
        getRoles,
        importUsers,
        resetUser,
        getUsers,
        getUserById,
        storeUser,
        deleteUsers,
        restoreUsers,
        getDirectories,
    }
})
