<template>
  <aside id="sidebar" class="flex hidden fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 w-64 h-full duration-75 lg:flex transition-width" aria-label="Sidebar">
    <div class="flex relative flex-col flex-1 pt-0 min-h-0 bg-gray-50 border-r border-gray-200">
      <div class="sidebar-content flex overflow-y-auto flex-col flex-1 pt-5 pb-16">
        <div class="flex-1 px-3 space-y-1 bg-gray-50 divide-y divide-gray-200">
          <ul class="pb-2 space-y-2">
            <li
                v-if="checkRole(['admin', 'manager', 'exhibitor'])"
                class="item" :class="{ active: $route.name === 'Dashboard' }">
              <router-link :to="{name: 'Dashboard'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                <span class="ml-3" sidebar-toggle-item>{{ $t('common.coordination') }}</span>
              </router-link>
            </li>
            <li
                class="item"
                v-if="checkRole(['admin', 'content_exhibitor', 'content_manager', 'manager'])"
                :class="{ active:
                  $route.name === 'Contents' || $route.name === 'ContentNewsCreate' || $route.name === 'ContentNewsEdit'
                  || $route.name === 'ContentProfileCreate' || $route.name === 'ContentProfileEdit'
                  || $route.name === 'ContentProductCreate' || $route.name === 'ContentProductEdit'
                  || $route.name === 'ContentAudioGuildCreate' || $route.name === 'ContentAudioGuildEdit'
                  || $route.name === 'ContentEventCreate' || $route.name === 'ContentEventEdit'
                }"
            >
              <router-link :to="{name: 'Contents'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                <span class="ml-3" sidebar-toggle-item>{{ 'Контент' }}</span>
              </router-link>
            </li>
            <li class="item" v-if="checkRole('admin')" :class="{ active: $route.name === 'Users' || $route.name === 'UserEdit' }">
              <router-link :to="{name: 'Users'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                <span class="ml-3" sidebar-toggle-item>{{ $t('common.users') }}</span>
              </router-link>
            </li>
            <li class="item" v-if="checkRole('admin')" :class="{ active: $route.name === 'UserLog' }">
              <router-link :to="{name: 'UserLog'}" class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                <span class="ml-3" sidebar-toggle-item>{{ $t('common.logs') }}</span>
              </router-link>
            </li>
            <li>
              <hr />
            </li>
            <li v-if="checkRole(['admin', 'manager'])">
              <button type="button" @click.prevent="isHideDirectories = !isHideDirectories" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-directories" data-collapse-toggle="dropdown-directories">
                <svg width="20" height="20" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                  <path d="M4.79999 19.2393V24.0393C4.79999 26.6905 9.81439 28.8393 16 28.8393C22.1856 28.8393 27.2 26.6905 27.2 24.0393V19.2393C27.2 21.8905 22.1856 24.0393 16 24.0393C9.81439 24.0393 4.79999 21.8905 4.79999 19.2393Z"
                        fill="currentColor"/>
                  <path d="M4.79999 11.2393V16.0393C4.79999 18.6905 9.81439 20.8393 16 20.8393C22.1856 20.8393 27.2 18.6905 27.2 16.0393V11.2393C27.2 13.8905 22.1856 16.0393 16 16.0393C9.81439 16.0393 4.79999 13.8905 4.79999 11.2393Z"
                        fill="currentColor"/>
                  <path d="M27.2 8.03926C27.2 10.6905 22.1856 12.8393 16 12.8393C9.81439 12.8393 4.79999 10.6905 4.79999 8.03926C4.79999 5.38806 9.81439 3.23926 16 3.23926C22.1856 3.23926 27.2 5.38806 27.2 8.03926Z" fill="currentColor"/>
                </svg>
                <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{{ $t('common.directory') }}</span>
                <svg class="w-6 h-6" :class="{'rotate-180': !isHideDirectories}" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>
              <ul class="py-2 space-y-2" :class="{'hidden': isHideDirectories}">
                <li class="item" v-if="checkRole(['admin'])" :class="{ active: $route.name === 'ListContour' || $route.name === 'ListContourCreate' || $route.name === 'ListContourEdit' }">
                  <router-link :to="{name: 'ListContour'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{{ $t('common.contourType') }}</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager'])" :class="{ active: $route.name === 'ListVenue' || $route.name === 'ListVenueCreate' || $route.name === 'ListVenueEdit' }">
                  <router-link :to="{name: 'ListVenue'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{{ $t('common.venues') }}</span>
                  </router-link>
                </li>
                <li class="item" :class="{ active: $route.name === 'DocsDirectory' || $route.name === 'DocsDirectoryEdit' }">
                  <router-link :to="{name: 'DocsDirectory'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{{ $t('common.documentType') }}</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin'])" :class="{ active: $route.name === 'ExhibitorTypes' || $route.name === 'ExhibitorTypesEdit' }">
                  <router-link :to="{name: 'ExhibitorTypes'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{{ $t('common.exhibitorType') }}</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin'])" :class="{ active: $route.name === 'FederalDistricts' || $route.name === 'FederalDistrictsEdit' }">
                  <router-link :to="{name: 'FederalDistricts'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>{{ $t('common.federalDistrict') }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <hr />
            </li>
            <li v-if="checkRole(['admin'])">
              <button type="button" @click.prevent="isHideContent = !isHideContent" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-directories" data-collapse-toggle="dropdown-directories">
                <svg width="20" height="20" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                  <path d="M4.79999 19.2393V24.0393C4.79999 26.6905 9.81439 28.8393 16 28.8393C22.1856 28.8393 27.2 26.6905 27.2 24.0393V19.2393C27.2 21.8905 22.1856 24.0393 16 24.0393C9.81439 24.0393 4.79999 21.8905 4.79999 19.2393Z"
                        fill="currentColor"/>
                  <path d="M4.79999 11.2393V16.0393C4.79999 18.6905 9.81439 20.8393 16 20.8393C22.1856 20.8393 27.2 18.6905 27.2 16.0393V11.2393C27.2 13.8905 22.1856 16.0393 16 16.0393C9.81439 16.0393 4.79999 13.8905 4.79999 11.2393Z"
                        fill="currentColor"/>
                  <path d="M27.2 8.03926C27.2 10.6905 22.1856 12.8393 16 12.8393C9.81439 12.8393 4.79999 10.6905 4.79999 8.03926C4.79999 5.38806 9.81439 3.23926 16 3.23926C22.1856 3.23926 27.2 5.38806 27.2 8.03926Z" fill="currentColor"/>
                </svg>
                <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Контент (списки)</span>
                <svg class="w-6 h-6" :class="{'rotate-180': !isHideContent}" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>
              <ul class="py-2 space-y-2" :class="{'hidden': isHideContent}">
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'ParticipantCategory' || $route.name === 'ParticipantCategoryShow' }">
                  <router-link :to="{name: 'ParticipantCategory'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Категория участника</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'Pavilion' || $route.name === 'PavilionShow' }">
                  <router-link :to="{name: 'Pavilion'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Павильоны</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'NewsCategory' || $route.name === 'NewsCategoryShow' }">
                  <router-link :to="{name: 'NewsCategory'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Категория новости</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'EventType' || $route.name === 'EventTypeShow' }">
                  <router-link :to="{name: 'EventType'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Тип мероприятия</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'EventCategory' || $route.name === 'EventCategoryShow' }">
                  <router-link :to="{name: 'EventCategory'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Категория мероприятия</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'EventOrganization' || $route.name === 'EventOrganizationShow' }">
                  <router-link :to="{name: 'EventOrganization'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Организатор мероприятия</span>
                  </router-link>
                </li>
                <li class="item" v-if="checkRole(['admin', 'manager', 'content_manager'])" :class="{ active: $route.name === 'AgeLimit' || $route.name === 'AgeLimitShow' }">
                  <router-link :to="{name: 'AgeLimit'}" :class="{'pl-11': interfaceStore.sidebarExpanded}" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" sidebar-toggle-subitem>
                    <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
                    <span class="flex-1 ml-3 text-left whitespace-nowrap" sidebar-toggle-item>Возрастное ограничение мероприятия</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <hr />
            </li>
          </ul>
        </div>
      </div>
      <div class="hidden absolute" sidebar-bottom-menu></div>
    </div>
  </aside>
</template>

<script setup>
import {useInterfaceStore} from "../../../stores/InterfaceStore";
import {checkRole} from "../../../utilits/functions";

import {ref} from 'vue';

const isHideDirectories = ref(true);
const isHideContent = ref(true);

const interfaceStore = useInterfaceStore();
</script>

<style>
#sidebar ul li.active {
  border-radius: 10px;
  background-color: #c6d6f4;
  color: #FFFFFF;
}

#sidebar ul li.item span {
  text-overflow: ellipsis;
  overflow: hidden;
}

#sidebar .sidebar-content::-webkit-scrollbar-button {
  background-image  : url('');
  background-repeat : no-repeat;
  width             : 5px;
  height            : 0;
}

#sidebar .sidebar-content::-webkit-scrollbar-thumb {
  -webkit-border-radius : 20px;
  border-radius         : 20px;
  background-color      : #c6d6f4;
}

#sidebar .sidebar-content::-webkit-scrollbar-thumb:hover {
  background-color : #56999F;
}

#sidebar .sidebar-content::-webkit-resizer {
  background-image  : url('');
  background-repeat : no-repeat;
  width             : 4px;
  height            : 0;
}

#sidebar .sidebar-content::-webkit-scrollbar {
  width  : 3px;
  height : 2px;
}
</style>
