<template>
  <spinner v-if="isLoadingData"/>
  <div v-if="!isLoadingData">

    <div
        v-if="saveMessageResponse"
        style="z-index: 100; top: 80px"
        id="toast-top-right"
        :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
        class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
        role="alert">
      <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
    </div>

    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <div>
                            <input-text v-model="nameRu" label="Наименование (RU) *"/>
                            <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
                          </div>
                          <div>
                            <input-text v-model="nameEn" :label="'Наименование (EN) ' + requiredValue"/>
                            <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
                          </div>
                        </div>
                      </div>

                      <hr class="mb-4 mb-2">

                      <div class="grid gap-4 mb-5 sm:grid-cols-2">
                        <div class="sm:col-span-2">
                          <input-textarea
                              class="placeholder-gray-100"
                              placeholder=""
                              v-model="shortDescriptionRu"
                              label="Краткое описание (RU) *"
                          />
                          <p v-if="shortDescriptionRuError" class="mt-2 text-sm text-red-600">
                            {{shortDescriptionRuError }}
                          </p>
                        </div>
                        <div class="sm:col-span-2">
                          <input-textarea
                              class="placeholder-gray-100"
                              placeholder=""
                              v-model="shortDescriptionEn"
                              :label="'Краткое описание (EN) ' + requiredValue"
                          />
                          <p v-if="shortDescriptionEnError" class="mt-2 text-sm text-red-600">
                            {{shortDescriptionEnError }}
                          </p>
                        </div>
                      </div>

                      <hr class="mb-4 mb-9 mt-10">

                      <div class="grid gap-4 mb-5 sm:grid-cols-2">
                        <div class="sm:col-span-2">
                          <input-textarea
                              class="placeholder-gray-100"
                              placeholder=""
                              v-model="descriptionRu"
                              label="Подробное описание (RU) *"
                          />
                          <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600">
                            {{descriptionRuError }}
                          </p>
                        </div>
                        <div class="sm:col-span-2">
                          <input-textarea
                              class="placeholder-gray-100"
                              placeholder=""
                              v-model="descriptionEn"
                              :label="'Подробное описание (EN) ' + requiredValue"
                          />
                          <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600">
                            {{descriptionEnError }}
                          </p>
                        </div>
                      </div>

                      <hr class="mb-4 mb-9 mt-10">

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text
                              v-model="sourceRu"
                              label="Источник (RU)"/>
                          <p v-if="sourceRuError" class="mt-2 text-sm text-red-600"> {{ sourceRuError }} </p>
                        </div>
                      </div>

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text v-model="sourceEn"
                                      label="Источник (EN)"/>
                          <p v-if="sourceEnError" class="mt-2 text-sm text-red-600"> {{ sourceEnError }} </p>
                        </div>
                      </div>

                      <hr class="mb-4 mb-9 mt-10">

                      <div
                          v-if="!checkRole(['content_exhibitor'])"
                          class="mb-5">
                        <label class="block mb-2 font-medium text-basic-gray">Выберите категорию новости *</label>
                        <Multiselect
                            v-model="selectedCategories"
                            :options="categories"
                            :mode="'tags'"
                            :valueProp="'id'"
                            max="1"
                            track-by="name"
                            label="name"
                            :close-on-select="false"
                            :searchable="true"/>
                        <p v-if="selectedCategoryError" class="mt-2 text-sm text-red-600">
                          {{
                            selectedCategoryError
                          }}
                        </p>
                      </div>

                      <div>
                        <span class="title text-gray-400">Дата публикации *</span>
                        <VueDatePicker
                            class="mt-2"
                            v-model="publishedDatetime"
                            locale="ru"
                            model-type="yyyy-MM-dd HH:mm"
                            :format="'dd.MM.yyyy HH:mm'"
                            auto-apply
                            :enable-time-picker="true"
                        />
                        <p v-if="publishedDatetimeError" class="mt-2 text-sm text-red-600">
                          {{
                            publishedDatetimeError
                          }}
                        </p>
                      </div>

                      <hr class="mb-4 mb-9 mt-10">

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <h2 class="mb-2" style="text-align: center">Загрузить изображение для анонса *</h2>
                          <drop-file
                              v-model="previewImage"
                              :type="previewImageTypes"
                              :single="true"
                          />
                          <p v-if="previewImageError" class="mt-2 text-sm text-red-600"> {{ previewImageError }} </p>
                        </div>
                      </div>

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <h2 class="mb-2" style="text-align: center">Загрузить изображение новости *</h2>
                          <drop-file
                              v-model="image"
                              :type="imageTypes"
                              :single="true"
                          />
                          <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
                        </div>
                      </div>
                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text
                              v-model="authorImageTitleNameRu"
                              label="Наименование автора изображения (RU) *"/>
                          <p v-if="authorImageTitleNameRuError" class="mt-2 text-sm text-red-600"> {{ authorImageTitleNameRuError }} </p>
                        </div>
                      </div>

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text
                              v-model="authorImageTitleNameEn"
                              :label="'Наименование автора изображения (EN) ' + requiredValue"/>
                          <p v-if="authorImageTitleNameEnError" class="mt-2 text-sm text-red-600"> {{ authorImageTitleNameEnError }} </p>
                        </div>
                      </div>

                      <hr class="mb-4 mb-9 mt-10">

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <h2 class="mb-2" style="text-align: center">Загрузить видео файл</h2>
                          <drop-file
                              v-model="video"
                              :type="videoTypes"
                              :single="true"
                          />
                          <p v-if="videoError" class="mt-2 text-sm text-red-600"> {{ videoError }} </p>
                        </div>
                      </div>
                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text
                              v-model="authorVideoTitleNameRu"
                              label="Наименование автора видео (RU)"/>
                          <p v-if="authorVideoTitleNameRuError" class="mt-2 text-sm text-red-600"> {{ authorVideoTitleNameRuError }} </p>
                        </div>
                      </div>

                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <input-text v-model="authorVideoTitleNameEn"
                                      label="Наименование автора видео (EN)"/>
                          <p v-if="authorVideoTitleNameEnError" class="mt-2 text-sm text-red-600"> {{ authorVideoTitleNameEnError }} </p>
                        </div>
                      </div>

                      <hr class="mb-4 mb-9 mt-10">

                      <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                        <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <input
                              id="bordered-checkbox-0"
                              type="checkbox"
                              v-model="isCardPreview"
                              name="bordered-checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <div class="ml-2 text-sm">
                            <label for="bordered-checkbox-0" class="font-medium text-basic-gray dark:text-gray-300">
                              Главная / карточки с превью
                            </label>
                            <p
                                id="helper-checkbox-text-0"
                                class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {{
                                isCardPreview ? 'Да' : 'Нет'
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <input
                              id="bordered-checkbox-1"
                              type="checkbox"
                              v-model="isMain"
                              name="bordered-checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <div class="ml-2 text-sm">
                            <label for="bordered-checkbox-1" class="font-medium text-basic-gray dark:text-gray-300">
                              Главная и список новостей
                            </label>
                            <p
                                id="helper-checkbox-text"
                                class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {{
                                isMain ? 'Да' : 'Нет'
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <input
                              id="bordered-checkbox-2"
                              type="checkbox"
                              v-model="isTopNews"
                              name="bordered-checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <div class="ml-2 text-sm">
                            <label for="bordered-checkbox-2" class="font-medium text-basic-gray dark:text-gray-300">
                              Главная и новости / Слайдер
                            </label>
                            <p
                                id="helper-checkbox-text-2"
                                class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {{
                                isTopNews ? 'Да' : 'Нет'
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <input
                              id="bordered-checkbox-3"
                              type="checkbox"
                              v-model="isMainAbout"
                              name="bordered-checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <div class="ml-2 text-sm">
                            <label for="bordered-checkbox-3" class="font-medium text-basic-gray dark:text-gray-300">
                              Страница "О выставке
                            </label>
                            <p
                                id="helper-checkbox-text-3"
                                class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {{
                                isMainAbout ? 'Да' : 'Нет'
                              }}
                            </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">
                      </div>

                      <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                        <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <input
                              id="bordered-checkbox-4"
                              type="checkbox"
                              v-model="isActiveRu"
                              name="bordered-checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <div class="ml-2 text-sm">
                            <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                              Активность для (RU)
                            </label>
                            <p
                                id="helper-checkbox-text-4"
                                class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {{
                                isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <input
                              id="bordered-checkbox-5"
                              type="checkbox"
                              v-model="isActiveEn"
                              name="bordered-checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <div class="ml-2 text-sm">
                            <label for="bordered-checkbox-5" class="font-medium text-basic-gray dark:text-gray-300">
                              Активность для (EN)
                            </label>
                            <p
                                id="helper-checkbox-text-5"
                                class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              {{
                                isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)'
                              }}
                            </p>
                          </div>
                        </div>
                        <hr class="mb-4 mb-2">
                      </div>
                    </form>
                  </div>

                  <div
                      v-if="isErrorVal"
                      style="padding: 5px 15px; margin-top: 40px; color: red;"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      <ul style="list-style: initial; padding: 15px;">
                        <li v-if="nameRuError"><span style="font-weight: bold">Наименование (RU):</span> {{nameRuError}}</li>
                        <li v-if="nameEnError"><span style="font-weight: bold">Наименование (EN):</span> {{nameEnError}}</li>
                        <li v-if="shortDescriptionRuError"><span style="font-weight: bold">Краткое описание (RU):</span> {{shortDescriptionRuError}}</li>
                        <li v-if="shortDescriptionEnError"><span style="font-weight: bold">Краткое описание (EN):</span> {{shortDescriptionEnError}}</li>
                        <li v-if="descriptionRuError"><span style="font-weight: bold">Подробное описание (RU):</span> {{descriptionRuError}}</li>
                        <li v-if="descriptionEnError"><span style="font-weight: bold">Подробное описание (EN):</span> {{descriptionEnError}}</li>
                        <li v-if="selectedCategoryError"><span style="font-weight: bold">Категория новости:</span> {{selectedCategoryError}}</li>
                        <li v-if="publishedDatetimeError"><span style="font-weight: bold">Дата публикации:</span> {{publishedDatetimeError}}</li>
                        <li v-if="previewImageError"><span style="font-weight: bold">Изображение для анонса:</span> {{previewImageError}}</li>
                        <li v-if="imageError"><span style="font-weight: bold">Изображение:</span> {{imageError}}</li>
                        <li v-if="authorImageTitleNameRuError"><span style="font-weight: bold">Наименование автора изображения (RU):</span> {{authorImageTitleNameRuError}}</li>
                        <li v-if="authorImageTitleNameEnError"><span style="font-weight: bold">Наименование автора изображения (EN):</span> {{authorImageTitleNameEnError}}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="p-7 flex justify-between">
                    <div class="p-7 flex justify-between">
                      <div v-if="!isLoadingCreate">
                        <div
                            v-if="checkRole(['admin', 'manager'])"
                            class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="onHandleClickCreate(6)"
                                   class="bg-status-6 inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                              <span>Согласовать</span>
                            </label>
                          </div>
                        </div>
                        <div
                            v-else
                            class="flex flex-wrap">
                          <div class="flex items-center mb-4">
                            <label @click.prevent="onHandleClickCreate(5)"
                                   class="bg-status-5 inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                              <span>Отправить организатору</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-span-2 mb-4 xl:mb-2">
              <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
                <div class="flex justify-between items-center p-7 border-b border-gray-200">
                  <div class="text-3xl font-medium">
                    {{ $t('document.remarksTitle') }}
                  </div>
                </div>
                <div class="p-7 pt-4">
                  <div class="flex-1 justify-between flex flex-col h-[50vh]">
                    <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                      <div class="chat-message" v-for="(message, index) in messages">
                        <div class="flex items-end" :class="{'justify-end': !message.isAnswer}">
                          <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                            <div>
                              <div
                                  @click="onHandleClickDeleteMessage(index)"
                                  style="position: absolute; right: 10px; top: 10px; font-size: 12px; cursor: pointer"
                                  class="icon-delete">
                                <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                              </div>
                              <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.isAnswer, 'bg-blue-600 text-white': !message.isAnswer}">
                                <div class="text-xs">
                                  {{ message.user.name }}
                                </div>
                                <div class="text-xs">
                                  {{ message.createdAt }}
                                </div>
                                <div class="text-sm">
                                  {{ message.message }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                      <div class="relative flex">
                          <textarea
                              :placeholder="$t('chat.write_message')"
                              v-model="messageData"
                              rows="5"
                              class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                          ></textarea>
                      </div>
                      <div v-if="checkRole(['admin', 'manager', 'content_manager'])" class="items-center mt-2">
                        <button :disabled="messages.length" type="button"
                                @click.prevent="onHandleClickSendMessage"
                                class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                          <template v-if="!loadDataMessage">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                          </template>
                          <template v-else>
                            <svg aria-hidden="true" class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </div>
</template>

<script setup>
import {checkRole} from "../../../../utilits/functions.js";
import Spinner from "../../../flowbite/spinner.vue";
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'
import {serviceContentNewsCreate} from "../../../../service/contents/news/serviceContentNews.js";
import {serviceNewsCategoryList} from "../../../../service/lists/serviceListNewsCategory.js";
import {useRouter} from 'vue-router'
import moment from "moment";

const router = useRouter();

const isLoadingData = ref(true);
const saveMessageResponse = ref(null);

const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const messages = ref([]);
const messageData = ref(null);
const isLoadingCreate = ref(false);

const nameRu = ref('');
const nameRuError = ref('');
const nameEn = ref('');
const nameEnError = ref('');
const shortDescriptionRu = ref('');
const shortDescriptionRuError = ref('');
const shortDescriptionEn = ref('');
const shortDescriptionEnError = ref('');
const descriptionRu = ref('');
const descriptionRuError = ref('');
const descriptionEn = ref('');
const descriptionEnError = ref('');
const sourceRu = ref('');
const sourceRuError = ref('');
const sourceEn = ref('');
const sourceEnError = ref('');
const categories = ref([]);
const selectedCategories = ref([]);
const selectedCategoryError = ref('');
const authorImageTitleNameRu = ref('');
const authorImageTitleNameRuError = ref('');
const authorImageTitleNameEn = ref('');
const authorImageTitleNameEnError = ref('');
const authorVideoTitleNameRu = ref('');
const authorVideoTitleNameRuError = ref('');
const authorVideoTitleNameEn = ref('');
const authorVideoTitleNameEnError = ref('');
const isCardPreview = ref(false);
const isMain = ref(false);
const isTopNews = ref(false);
const isMainAbout = ref(false);
const isActiveRu = ref(true);
const isActiveEn = ref(true);
const publishedDatetime = ref(null);
const publishedDatetimeError = ref(null);

const isErrorVal = ref(false);
// requiredValue - нужно для того, чтобы выставить (*) где обязательность заполнение для администрации
const requiredValue = checkRole(['admin', 'manager', 'content_manager']) ? '*' : '';

const previewImage = ref(null);
const previewImageError = ref('');
const previewImageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 1,
};

const image = ref(null);
const imageError = ref('');
const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const video = ref(null);
const videoError = ref('');
const videoTypes = {
  extensions: ["mp4"],
  max_filesize: 100,
};

serviceNewsCategoryList().then(response => {
  isLoadingData.value = false;
  categories.value = response.data || [];
});

const onHandleClickCreate = (newStatus) => {
  const isValExhibitor = validateExhibitor();

  let isValAdministrationAgreed = null;
  if (!checkRole(['content_exhibitor']) && newStatus === 6) {
    isValAdministrationAgreed = validateAdministrationAgreed();
  }

  isErrorVal.value = isValExhibitor || isValAdministrationAgreed;

  if (isValExhibitor || isValAdministrationAgreed)
    return null;

  let messageItems = [];
  if (checkRole(['content_exhibitor'])) {
    if (messageData.value)
      messageItems = [messageData.value];
  }
  else messageItems = messages.value.length ? [messages.value[0].message] : [];

  const params = {
    status: newStatus,
    nameRu: nameRu.value,
    nameEn: nameEn.value,
    shortDescriptionRu: shortDescriptionRu.value,
    shortDescriptionEn: shortDescriptionEn.value,
    descriptionRu: descriptionRu.value,
    descriptionEn: descriptionEn.value,
    sourceRu: sourceRu.value,
    sourceEn: sourceEn.value,
    categoryId: selectedCategories.value.length ? selectedCategories.value[0] : null,
    publishedDatetime: publishedDatetime.value,
    previewImage: previewImage.value.length ? previewImage.value[0] : null,
    image: image.value.length ? image.value[0] : null,
    authorImageTitleNameRu: authorImageTitleNameRu.value,
    authorImageTitleNameEn: authorImageTitleNameEn.value,
    video: video.value?.length ? video.value[0] : null,
    videoAuthorRu: authorVideoTitleNameRu.value,
    videoAuthorEn: authorVideoTitleNameEn.value,
    isCardPreview: isCardPreview.value,
    isMain: isMain.value,
    isTopNews: isTopNews.value,
    isMainAbout: isMainAbout.value,
    isActiveRu: isActiveRu.value,
    isActiveEn: isActiveEn.value,
    messages: messageItems,
  };

  isLoadingCreate.valaue = true;
  serviceContentNewsCreate(params).then(response => {
    const status = response?.success;
    const message = status ? 'Успешно добавлено' : 'Ошибка, данные не были созданы';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      isLoadingCreate.valaue = false;
      saveMessageResponse.value = null;
    }, 3000);

    if (response.success && response?.data?.id) {
      router.push({name: 'ContentNewsEdit', params: {id: response?.data?.id}});
    } else {
      console.log('error')
    }
  });
}

const validateExhibitor = () => {
  let isError = false;

  const valueName = nameRu.value?.trim();
  nameRuError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameRuError.value)
    isError = true;

  const valueShortDescriptionRu = shortDescriptionRu.value?.trim();
  shortDescriptionRuError.value = valueShortDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionRuError.value)
    isError = true;

  const valueDescriptionRu = descriptionRu.value?.trim();
  descriptionRuError.value = valueDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionRuError.value)
    isError = true;

  const valueAuthorImageTitleNameRu = authorImageTitleNameRu.value?.trim();
  authorImageTitleNameRuError.value = valueAuthorImageTitleNameRu?.length ? '' : 'Поле не должно быть пустым';
  if (authorImageTitleNameRuError.value)
    isError = true;

  publishedDatetimeError.value = publishedDatetime.value ? '' : 'Поле не должно быть пустым';
  if (publishedDatetimeError.value)
    isError = true;

  previewImageError.value = previewImage.value ? '' : 'Вы не выбрали файл';
  if (previewImageError.value)
    isError = true;

  imageError.value = image.value ? '' : 'Вы не выбрали файл';
  if (imageError.value)
    isError = true;

  return isError;
}

const validateAdministrationAgreed = () => {
  let isError = false;

  selectedCategoryError.value = selectedCategories.value?.length ? '' : 'Вы не выбрали категорию новости';
  if (selectedCategoryError.value)
    isError = true;

  const valueName = nameEn.value?.trim();
  nameEnError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameEnError.value)
    isError = true;

  const valueShortDescriptionEn = shortDescriptionEn.value?.trim();
  shortDescriptionEnError.value = valueShortDescriptionEn?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionEnError.value)
    isError = true;

  const valueDescriptionEn = descriptionEn.value?.trim();
  descriptionEnError.value = valueDescriptionEn?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionEnError.value)
    isError = true;

  const valueAuthorImageTitleNameEn = authorImageTitleNameEn.value?.trim();
  authorImageTitleNameEnError.value = valueAuthorImageTitleNameEn?.length ? '' : 'Поле не должно быть пустым';
  if (authorImageTitleNameEnError.value)
    isError = true;

  return isError;
}

const onHandleClickDeleteMessage = (index) => {
  messages.value.splice(index, 1);
}

const onHandleClickSendMessage = () => {
  if (messages.value.length)
    return null;

  messages.value.push({
    message: messageData.value,
    createdAt: moment().format('DD.MM.YYYY HH:mm'),
    isAnswer: false,
    user,
  });

  messageData.value = null;
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>
