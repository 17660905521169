import {getToken, handleAsync} from "../../handleAsync.js";
import axios from "axios";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

function queryParams(params) {
    let query = '';

    if (params?.status) {
        query = '?status=' + params.status;
    }
    if (params?.exhibitor) {
        query += (!query.length ? ('?') : ('&')) + ('exhibitor=' + params.exhibitor);
    }
    if (params?.createdAt) {
        query += (!query.length ? ('?') : ('&')) + ('createdAt=' + params.createdAt);
    }
    if (params?.updatedAt) {
        query += (!query.length ? ('?') : ('&')) + ('updatedAt=' + params.updatedAt);
    }
    if (params?.search) {
        query += (!query.length ? ('?') : ('&')) + ('search=' + params.search);
    }

    return query;
}

export async function serviceContentNewsList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/news` + queryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentNewsFindId(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/news/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentNewsCodeUnique(code) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/news-code-unique/${code}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentNewsMultiDelete(ids) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/news/multi-delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ids})
    }));
}

export async function serviceContentNewsCreate(data) {
    const token = await getToken();

    return await axios.post(`${url}/contents/news/create`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceContentNewsUpdate(id, data) {
    const token = await getToken();

    return await axios.post(`${url}/contents/news/${id}/edit`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceContentNewsMessageCreate(id, params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/news/${id}/message/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    }));
}
