<template>
  <div>
    <label
        class="block text-gray-600 relative text-center border border-dashed border-2 rounded-lg border-gray-200 p-7 cursor-pointer opacity-80 hover:opacity-100"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        :class="{'bg-white opacity-100': isDragging}"
    >
      <input
          id="file"
          type="file"
          @change="onChange"
          ref="file"
          accept=".doc,.docx,.xlsx,.pdf,.png,.svg,.mp3,.mp4"
          class="opacity-0 overflow-hidden absolute w-px h-px"
          :multiple="!single" />

      <span class="flex align-center justify-center text-sm font-medium font-medium mb-3">
        <svg class="mr-5" width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.16602 21.667L8.80935 14.0237C9.43444 13.3988 10.2821 13.0477 11.166 13.0477C12.0499 13.0477 12.8976 13.3988 13.5227 14.0237L21.166 21.667M17.8327 18.3337L20.476 15.6903C21.1011 15.0654 21.9488 14.7144 22.8327 14.7144C23.7166 14.7144 24.5643 15.0654 25.1893 15.6903L27.8327 18.3337M17.8327 8.33366H17.8493M4.49935 28.3337H24.4993C25.3834 28.3337 26.2312 27.9825 26.8564 27.3573C27.4815 26.7322 27.8327 25.8844 27.8327 25.0003V5.00033C27.8327 4.11627 27.4815 3.26842 26.8564 2.6433C26.2312 2.01818 25.3834 1.66699 24.4993 1.66699H4.49935C3.61529 1.66699 2.76745 2.01818 2.14233 2.6433C1.5172 3.26842 1.16602 4.11627 1.16602 5.00033V25.0003C1.16602 25.8844 1.5172 26.7322 2.14233 27.3573C2.76745 27.9825 3.61529 28.3337 4.49935 28.3337Z" stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="pt-1">{{ $t('document.upload_files') }}</span>
      </span>
      <span class="block text-xs font-medium">{{ $t('document.format') }}: {{ type.extensions.join(', ') }}.<br>{{ $t('document.max_weight') }}: {{ type.max_filesize }} Mb</span>
    </label>
  </div>
  <p v-if="error" class="mt-2 text-sm text-red-600"> {{ error }} </p>
  <div v-if="files.length" class="mt-4">
    <div v-for="file in files" class="mb-2">
      <div class="flex">
        <span class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4">{{ file.name }}</span>
        <button @click.prevent="deleteFile(file)" type="button" class="hover:opacity-60">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../../i18n.js";
import {serviceFileUnique} from "../../../service/serviceFileUnique.js";

export default {
  props: {
    modelValue: Array,
    type: Object,
    single: {
      type: Boolean,
      default: false, // Значение по умолчанию - false (загрузка нескольких файлов)
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      error: false,
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      const newFiles = Array.from(this.$refs.file.files);
      const maxSizeInMb = this.type.max_filesize;
      const maxSizeInBytes = maxSizeInMb * 1024 * 1024;

      const invalidFiles = newFiles.filter((file) => {
        return !this.validateExtension(file.name);
      });

      if (invalidFiles.length > 0) {
        // Генерируем сообщение об ошибке для неверного формата
        const invalidFileMessages = invalidFiles.map((file) => {
          return `Вы загрузили файл "${file.name}" с недопустимым форматом (${file.type}). Файл был удален.`;
        });

        this.error = invalidFileMessages.join('\n');
      } else {
        this.error = false;
        if (newFiles.some((file) => file.size > maxSizeInBytes)) {
          this.error = i18n.global.t('document.max_weight') + ' ' + maxSizeInMb + ' Mb';
        } else {
          if (this.single) {
            this.files = newFiles;
          } else {
            // Фильтруем дубликаты и добавляем новые файлы
            const uniqueNewFiles = newFiles.filter(
                (file) =>
                    !this.files.some((existingFile) => existingFile.name === file.name)
            );

            this.files = [...this.files, ...uniqueNewFiles];
          }
        }

        if (!this.error) {
          this.$emit('update:modelValue', this.files);
        }
      }

      document.getElementById('file').value = '';
    },

    deleteFile(file) {
      this.files = this.files.filter(item => item !== file)

      this.$emit('update:modelValue', this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    validateExtension(extension) {
      let allowedFiles = []

      this.type.extensions.forEach((extensions) => {
        extensions.split('/').forEach((extension) => {
          allowedFiles.push(extension)
        })
      })

      let regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$")

      return regex.test(extension.toLowerCase())
    }
  },
};
</script>
