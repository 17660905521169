import {getToken, handleAsync} from "../../handleAsync.js";
import axios from "axios";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

function queryParams(params) {
    let query = '';

    if (params?.status) {
        query = '?status=' + params.status;
    }
    if (params?.exhibitor) {
        query += (!query.length ? ('?') : ('&')) + ('exhibitor=' + params.exhibitor);
    }
    if (params?.createdAt) {
        query += (!query.length ? ('?') : ('&')) + ('createdAt=' + params.createdAt);
    }
    if (params?.updatedAt) {
        query += (!query.length ? ('?') : ('&')) + ('updatedAt=' + params.updatedAt);
    }
    if (params?.search) {
        query += (!query.search ? ('?') : ('&')) + ('search=' + params.search);
    }

    return query;
}

export async function serviceContentProfileList(params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles` + queryParams(params), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentCheckCreate() {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles/check-create`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentProfileAchievementDelete(id, achievementId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles/${id}/achievement/${achievementId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentProfileSliderDelete(id, sliderId) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles/${id}/sliders/${sliderId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentProfileMultiDelete(ids) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles/multi-delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({ids})
    }));
}

export async function serviceContentProfileFindId(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContentProfileCreate(data) {
    const token = await getToken();

    return await axios.post(`${url}/contents/profiles/create`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceContentProfileUpdate(id, data) {
    const token = await getToken();

    return await axios.post(`${url}/contents/profiles/${id}/edit`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then((r) => {
            const response = r.data.layouts;

            return {
                data: response?.data,
                success: response?.success || false,
            };
        }).catch((e) => {
            const response = e.response.data;

            return {
                success: false,
                errors: response.errors || [],
                onlyErrors: response.onlyErrors || [],
            };
        });
}

export async function serviceContentProfileMessageCreate(id, params) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contents/profiles/${id}/message/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(params)
    }));
}
