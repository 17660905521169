<template>
  <spinner v-if="isLoadingData"/>
  <div v-if="!isLoadingData">

    <modal
        v-if="isShowModalSessionDelete"
        @closeDialog="isShowModalSessionDelete=false"
    >
      <template #title>Удаление сессии</template>
      <template #body>
        <form action="#">
          <div class="grid gap-4 mb-5 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <label class="block mb-2 font-medium text-basic-gray">Вы точно хотите удалить данную сессию?</label>
            </div>
          </div>
          <div
              class="flex items-center space-x-4">
            <button @click.prevent="onHandleSessionDelete" type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Да
            </button>
            <button @click="onHandleModalSessionClose" type="button"
                    class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              {{ $t('common.cancel') }}
            </button>
          </div>
        </form>
      </template>
    </modal>

    <modal
        v-if="isShowModalSliderDelete"
        @closeDialog="isShowModalSliderDelete=false"
    >
      <template #title>Удаление слайда</template>
      <template #body>
        <form action="#">
          <div class="grid gap-4 mb-5 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <label class="block mb-2 font-medium text-basic-gray">Вы точно хотите удалить данный слайд?</label>
            </div>
          </div>
          <div
              class="flex items-center space-x-4">
            <button @click.prevent="onHandleSliderDelete" type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Да
            </button>
            <button @click="onHandleModalSliderClose" type="button"
                    class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              {{ $t('common.cancel') }}
            </button>
          </div>
        </form>
      </template>
    </modal>

    <div
        v-if="saveMessageResponse"
        style="z-index: 100; top: 80px"
        id="toast-top-right"
        :class="saveMessageResponse.status ? 'bg-green-200' : 'bg-red-200'"
        class="fixed flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
        role="alert">
      <div class="text-sm font-normal text-black">{{ saveMessageResponse.message }}</div>
    </div>

    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div class="p-7 pb-12 border-b border-gray-200">
                    <form action="">
                      <div class="grid gap-4 mb-10 sm:grid-cols-3">
                        <div class="sm:col-span-2">
                          <div>
                            <div>
                              <span class="text-3xl font-medium mr-4">{{ nameRu }}</span>
                            </div>
                          </div>

                          <div class="mt-2" v-if="eventId">
                            <p style="color: #9b9b9b;">Участник: <span style="color: #000;">{{participant?.nameRu}}</span></p>
                            <p style="color: #9b9b9b;">Пользователь: <span style="color: #000;">{{userCreated?.name}}</span></p>
                          </div>
                        </div>

                        <div class="sm:col-span-1">
                          <div class="text-right text-sm">
                            <p class="mb-2"><span class="text-gray-500">{{ $t('document.created') }}:</span>
                              {{ moment(createdAt).format('DD.MM.YYYY') }}</p>
                            <p class="mb-2"><span class="text-gray-500">{{ $t('document.updated') }}:</span>
                              {{ moment(updatedAt).format('DD.MM.YYYY') }}</p>
                            <p class="mb-2" v-if="checkRole('admin')">
                              <router-link
                                  :to="{name: 'UserLog', query: {object: 'App\\Models\\Api\\V1\\ContentEvent', id: eventId.value}}"
                                  target="_blank"
                                  class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">
                                {{ $t('common.toLogs') }}
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="grid gap-4 mb-4">
                        <div class="sm:col-span-2">
                          <div class="grid gap-4 mb-4 sm:grid-cols-2 mt-5" v-if="status">
                            <div class="sm:col-span-2">
                              <div
                                  class="inline-block px-4 py-1 rounded-xl mr-4 whitespace-nowrap opacity-100 cursor-default"
                                  :class="['bg-status-' + (status === 14 ? 6 : status)]">
                                <span>{{ statusAction?.name }}</span>
                              </div>
                            </div>
                          </div>
                          <br />
                          <hr/>
                          <br />
                        </div>
                      </div>

                      <div
                          style="display: flex; justify-content: space-around; align-items: center; text-align: center;"
                          class="tabs">
                        <div
                            :class="{'active': tabKey === 'main'}"
                            style="padding: 10px; width: 100%;"
                            @click="onHandleClickTabKey('main')"
                            class="tab">
                          Основное
                        </div>
                        <div
                            :class="{'active': tabKey === 'session'}"
                            style="padding: 10px; width: 100%;"
                            @click="onHandleClickTabKey('session')"
                            class="tab">
                          Сеансы {{sessions.length ? `(${sessions.length})` : ''}}
                        </div>
                        <div
                            :class="{'active': tabKey === 'sliders'}"
                            style="padding: 10px; width: 100%;"
                            @click="onHandleClickTabKey('sliders')"
                            class="tab">
                          Слайдер {{sliders.length ? `(${sliders.length})` : ''}}
                        </div>
                      </div>

                      <div v-if="tabKey === 'sliders'">
                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageSliderEmpty" class="alert-message-error">
                          {{messageSliderEmpty}}
                        </div>
                        <div
                            v-for="(slider, index) in sliders"
                            :key="index"
                            class="achievement-content-item">
                          <div class="achievement-content-item-image mb-7">
                            <div
                                v-if="typeof slider.image !== 'string'"
                                class="grid gap-4 mb-4">
                              <div class="sm:col-span-2">
                                <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                                <drop-file
                                    :disabled="!isNewsEdit"
                                    v-model="slider.image"
                                    :type="imageTypes"
                                    :single="true"
                                />
                                <p v-if="slider.imageError" class="mt-2 text-sm text-red-600"> {{ slider.imageError }} </p>
                              </div>
                            </div>
                            <div
                                v-else
                                class="image-content" style="text-align: center; width: 250px; margin: auto;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="slider.image"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="slider.image=null"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                          <hr/>
                          <div class="author-achievement mb-5 mt-5">
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="slider.authorRu"
                                  label="Наименование автора изображения (RU)"/>
                              <p v-if="slider.authorRuError" class="mt-2 text-sm text-red-600"> {{ slider.authorRuError }} </p>
                            </div>
                            <div>
                              <input-text
                                  :disabled="!isNewsEdit"
                                  v-model="slider.authorEn"
                                  label="Наименование автора изображения (EN)"/>
                              <p v-if="slider.authorEnError" class="mt-2 text-sm text-red-600"> {{ slider.authorEnError }} </p>
                            </div>
                          </div>
                          <hr />
                          <button
                              :disabled="!isNewsEdit"
                              type="button"
                              @click="onHandleDeleteActionModelSlider(index, slider?.id)"
                              class="px-7 mr-5 mt-5 py-3 mb-5 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                            Удалить
                          </button>
                        </div>

                        <div
                            @click="onHandleClickSliderCreate"
                            class="session-add">
                          <div class="session-btn">
                            Добавить новый слайд
                          </div>
                        </div>
                      </div>

                      <div
                          v-if="tabKey === 'session'"
                          class="tab-content tab-content-session">

                        <div
                            style="padding: 5px 15px; color: red; text-align: center;"
                            v-if="messageSessionEmpty" class="alert-message-error">
                          {{messageSessionEmpty}}
                        </div>

                        <div class="sessions">
                          <div
                              v-for="(session, index) in sessions"
                              :key="index"
                              class="session-item">
                            <div
                                v-if="session.isGenerate"
                                class="session-content-week">
                              <p style="padding: 10px 5px 5px 10px">Дни недели *</p>
                              <div class="session-week">
                                <div
                                    v-for="(week, index) in session.weeks"
                                    class="session-week-item">
                                  <div class="week flex items-center mb-4">
                                    <input
                                        v-model="week.value"
                                        :id="'checkbox-' + index"
                                        type="checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" >
                                    <label :for="'checkbox-' + index" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ week.name }}</label>
                                  </div>
                                </div>
                              </div>
                              <div class="grid gap-4 mb-4 sm:grid-cols-2">
                                <div class="sm:col-span-2">
                                  <div>
                                    <input-text
                                        :disabled="!isNewsEdit"
                                        v-model="session.name"
                                        label="Квота на сеанс *"/>
                                    <p v-if="session.nameError" class="mt-2 text-sm text-red-600"> {{
                                        session.nameError
                                      }} </p>
                                  </div>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата начала *</span>
                                  <VueDatePicker
                                      :disabled="!isNewsEdit"
                                      class="mt-2"
                                      v-model="session.dateStart"
                                      locale="ru"
                                      model-type="yyyy-MM-dd"
                                      :format="'dd.MM.yyyy'"
                                      auto-apply
                                      :enable-time-picker="false"
                                  />
                                  <p v-if="session.dateStartError" class="mt-2 text-sm text-red-600">
                                    {{ session.dateStartError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата окончания *</span>
                                  <VueDatePicker
                                      :disabled="!isNewsEdit"
                                      class="mt-2"
                                      v-model="session.dateEnd"
                                      locale="ru"
                                      model-type="yyyy-MM-dd"
                                      :format="'dd.MM.yyyy'"
                                      auto-apply
                                      :enable-time-picker="false"
                                  />
                                  <p v-if="session.dateEndError" class="mt-2 text-sm text-red-600">
                                    {{ session.dateEndError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <div>
                                    <span class="title text-gray-400">Время начала *</span>
                                    <VueDatePicker
                                        :disabled="!isNewsEdit"
                                        class="mt-2"
                                        v-model="session.timeStart"
                                        locale="ru"
                                        model-type="HH:mm"
                                        :format="'HH:mm'"
                                        auto-apply
                                        :enable-time-picker="true"
                                        time-picker
                                    />
                                    <p v-if="session.timeStartError" class="mt-2 text-sm text-red-600">
                                      {{ session.timeStartError }} </p>
                                  </div>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Время окончания *</span>
                                  <VueDatePicker
                                      :disabled="!isNewsEdit"
                                      class="mt-2"
                                      v-model="session.timeEnd"
                                      locale="ru"
                                      model-type="HH:mm"
                                      :format="'HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                      time-picker
                                  />
                                  <p v-if="session.timeEndError" class="mt-2 text-sm text-red-600">
                                    {{ session.timeEndError }} </p>
                                </div>

                                <div class="sm:col-span-1">
                                  <span class="title text-gray-400">Дата планового открытия регистрации</span>
                                  <VueDatePicker
                                      :disabled="!isNewsEdit"
                                      class="mt-2"
                                      v-model="session.datePlanOpen"
                                      locale="ru"
                                      model-type="yyyy-MM-dd HH:mm"
                                      :format="'dd.MM.yyyy'"
                                      auto-apply
                                      :enable-time-picker="true"
                                  />
                                </div>

                                <div class="sm:col-span-2"/>

                                <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                                  <div
                                      class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input
                                        :disabled="!isNewsEdit"
                                        :id="'bordered-checkbox-reg-' + index"
                                        type="checkbox"
                                        v-model="session.isOpenRegistration"
                                        name="bordered-checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <div class="ml-2 text-sm">
                                      <label :for="'bordered-checkbox-reg-' + index"
                                             class="font-medium text-basic-gray dark:text-gray-300">
                                        Признак открытия регистрации
                                      </label>
                                      <p
                                          :id="'helper-checkbox-text-reg-' + index"
                                          class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{
                                          session.isOpenRegistration ? 'Активна (Да)' : 'Не активна (Нет)'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                      class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input
                                        :disabled="!isNewsEdit"
                                        :id="'bordered-checkbox-active-' + index"
                                        type="checkbox"
                                        v-model="session.isActive"
                                        name="bordered-checkbox"
                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <div class="ml-2 text-sm">
                                      <label :for="'bordered-checkbox-active-' + index"
                                             class="font-medium text-basic-gray dark:text-gray-300">
                                        Активность
                                      </label>
                                      <p
                                          :id="'helper-checkbox-text-active-' + index"
                                          class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                        {{
                                          session.isActive ? 'Активна (Да)' : 'Не активна (Нет)'
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <button
                                    type="button"
                                    :disabled="!isNewsEdit"
                                    @click="onHandleDeleteActionModelSession(index, session?.id)"
                                    style="margin-bottom: 30px"
                                    class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                  Удалить
                                </button>
                              </div>
                            </div>

                            <div
                                v-else
                                class="grid gap-4 mb-4 sm:grid-cols-2">
                              <div class="sm:col-span-1">
                                <div>
                                  <input-text
                                      :disabled="!isNewsEdit"
                                      v-model="session.name"
                                      label="Квота на сеанс *"/>
                                  <p v-if="session.nameError" class="mt-2 text-sm text-red-600"> {{
                                      session.nameError
                                    }} </p>
                                </div>
                              </div>

                              <div class="sm:col-span-1">
                                <span class="title text-gray-400">Дата начала *</span>
                                <VueDatePicker
                                    :disabled="!isNewsEdit"
                                    class="mt-2"
                                    v-model="session.dateStart"
                                    locale="ru"
                                    model-type="yyyy-MM-dd"
                                    :format="'dd.MM.yyyy'"
                                    auto-apply
                                    :enable-time-picker="false"
                                />
                                <p v-if="session.dateStartError" class="mt-2 text-sm text-red-600">
                                  {{ session.dateStartError }} </p>
                              </div>

                              <div class="sm:col-span-1">
                                <div>
                                  <span class="title text-gray-400">Время начала *</span>
                                  <VueDatePicker
                                      :disabled="!isNewsEdit"
                                      class="mt-2"
                                      v-model="session.timeStart"
                                      locale="ru"
                                      model-type="HH:mm"
                                      :format="'HH:mm'"
                                      auto-apply
                                      :enable-time-picker="true"
                                      time-picker
                                  />
                                  <p v-if="session.timeStartError" class="mt-2 text-sm text-red-600">
                                    {{ session.timeStartError }} </p>
                                </div>
                              </div>

                              <div class="sm:col-span-1">
                                <span class="title text-gray-400">Время окончания *</span>
                                <VueDatePicker
                                    :disabled="!isNewsEdit"
                                    class="mt-2"
                                    v-model="session.timeEnd"
                                    locale="ru"
                                    model-type="HH:mm"
                                    :format="'HH:mm'"
                                    auto-apply
                                    :enable-time-picker="true"
                                    time-picker
                                />
                                <p v-if="session.timeEndError" class="mt-2 text-sm text-red-600">
                                  {{ session.timeEndError }} </p>
                              </div>

                              <div class="sm:col-span-1">
                                <span class="title text-gray-400">Дата планового открытия</span>
                                <VueDatePicker
                                    :disabled="!isNewsEdit"
                                    class="mt-2"
                                    v-model="session.datePlanOpen"
                                    locale="ru"
                                    model-type="yyyy-MM-dd HH:mm"
                                    :format="'dd.MM.yyyy'"
                                    auto-apply
                                    :enable-time-picker="true"
                                />
                              </div>

                              <div class="sm:col-span-2"/>

                              <div v-if="!checkRole(['exhibitor', 'content_exhibitor'])">
                                <div
                                    class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                  <input
                                      :disabled="!isNewsEdit"
                                      :id="'bordered-checkbox-reg-not-generate-' + index"
                                      type="checkbox"
                                      v-model="session.isOpenRegistration"
                                      name="bordered-checkbox"
                                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                  <div class="ml-2 text-sm">
                                    <label :for="'bordered-checkbox-reg-not-generate-' + index"
                                           class="font-medium text-basic-gray dark:text-gray-300">
                                      Признак открытия регистрации
                                    </label>
                                    <p
                                        :id="'helper-checkbox-text-reg-not-generate-' + index"
                                        class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                      {{
                                        session.isOpenRegistration ? 'Активна (Да)' : 'Не активна (Нет)'
                                      }}
                                    </p>
                                  </div>
                                </div>
                                <div
                                    class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                                  <input
                                      :disabled="!isNewsEdit"
                                      :id="'bordered-checkbox-active-not-generate-' + index"
                                      type="checkbox"
                                      v-model="session.isActive"
                                      name="bordered-checkbox"
                                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                  <div class="ml-2 text-sm">
                                    <label :for="'bordered-checkbox-active-not-generate-' + index"
                                           class="font-medium text-basic-gray dark:text-gray-300">
                                      Активность
                                    </label>
                                    <p
                                        :id="'helper-checkbox-text-active-not-generate-' + index"
                                        class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                      {{
                                        session.isActive ? 'Активна (Да)' : 'Не активна (Нет)'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <button
                                  type="button"
                                  :disabled="!isNewsEdit"
                                  @click="onHandleDeleteActionModelSession(index, session?.id)"
                                  style="margin-bottom: 30px"
                                  class="rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                              v-if="isNewsEdit"
                              @click="onHandleClickSessionCreate"
                              class="session-add">
                            <div class="session-btn">
                              Добавить новый сеанс
                            </div>
                          </div>
<!--                          <div-->
<!--                              v-if="!sessions.find(item => item?.isGenerate === true)"-->
<!--                              @click="onHandleClickSessionGenerate"-->
<!--                              class="session-add">-->
<!--                            <div class="session-btn">-->
<!--                              Сгенерировать сеанс-->
<!--                            </div>-->
<!--                          </div>-->
                        </div>
                      </div>

                      <div v-if="tabKey === 'main'"
                           class="tab-content">
                        <div>
                          <input-text
                              :disabled="!isNewsEdit"
                              v-model="nameRu"
                              label="Наименование (RU) *"/>
                          <p v-if="nameRuError" class="mt-2 text-sm text-red-600"> {{ nameRuError }} </p>
                        </div>
                        <div class="mt-5">
                          <input-text
                              :disabled="!isNewsEdit"
                              v-model="nameEn"
                              :label="'Наименование (EN) ' + requiredValue"/>
                          <p v-if="nameEnError" class="mt-2 text-sm text-red-600"> {{ nameEnError }} </p>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionRu"
                                label="Краткое описание (RU) *"
                            />
                            <p v-if="shortDescriptionRuError" class="mt-2 text-sm text-red-600"> {{ shortDescriptionRuError }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="shortDescriptionEn"
                                :label="'Краткое описание (EN) ' + requiredValue"
                            />
                            <p v-if="shortDescriptionEnError" class="mt-2 text-sm text-red-600"> {{ shortDescriptionEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-5 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionRu"
                                label="Подробное описание (RU) *"
                            />
                            <p v-if="descriptionRuError" class="mt-2 text-sm text-red-600"> {{ descriptionRuError }} </p>
                          </div>
                          <div class="sm:col-span-2">
                            <input-textarea
                                :disabled="!isNewsEdit"
                                class="placeholder-gray-100"
                                placeholder=""
                                v-model="descriptionEn"
                                :label="'Подробное описание (EN) ' + requiredValue"
                            />
                            <p v-if="descriptionEnError" class="mt-2 text-sm text-red-600"> {{ descriptionEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div
                            v-if="checkRole(['admin', 'content_manager', 'manager'])"
                            class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите категорию мероприятия *</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedCategories"
                              :options="categories"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="nameRu"
                              label="nameRu"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedCategoryError" class="mt-2 text-sm text-red-600">
                            {{ selectedCategoryError }}
                          </p>
                        </div>

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите организатора мероприятия *</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedOrganizations"
                              :options="organizations"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="nameRu"
                              label="nameRu"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedOrganizationError" class="mt-2 text-sm text-red-600">
                            {{ selectedOrganizationError }}
                          </p>
                        </div>

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите ограничение по возрасту *</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedAgeLimits"
                              :options="ageLimits"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="nameRu"
                              label="nameRu"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedAgeLimitError" class="mt-2 text-sm text-red-600">
                            {{ selectedAgeLimitError }}
                          </p>
                        </div>

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите тип мероприятия *</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedTypes"
                              :options="types"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="titleRu"
                              label="titleRu"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedTypeError" class="mt-2 text-sm text-red-600">
                            {{ selectedTypeError }}
                          </p>
                        </div>

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите павильон</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedPavilions"
                              :options="pavilions"
                              :mode="'tags'"
                              :valueProp="'id'"
                              max="1"
                              track-by="name"
                              label="name"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedPavilionError" class="mt-2 text-sm text-red-600">
                            {{ selectedPavilionError }}
                          </p>
                        </div>

                        <div class="mb-5">
                          <label class="block mb-2 font-medium text-basic-gray">Выберите группу</label>
                          <Multiselect
                              :disabled="!isNewsEdit"
                              v-model="selectedGroups"
                              :options="groups"
                              :mode="'tags'"
                              :valueProp="'id'"
                              track-by="nameRu"
                              label="nameRu"
                              :close-on-select="false"
                              :searchable="true"/>
                          <p v-if="selectedGroupError" class="mt-2 text-sm text-red-600">
                            {{ selectedGroupError }}
                          </p>
                        </div>

                        <div>
                          <span class="title text-gray-400">Дата публикации *</span>
                          <VueDatePicker
                              :disabled="!isNewsEdit"
                              class="mt-2"
                              v-model="publishedAt"
                              locale="ru"
                              model-type="yyyy-MM-dd HH:mm"
                              :format="'dd.MM.yyyy HH:mm'"
                              auto-apply
                              :enable-time-picker="true"
                          />
                          <p v-if="publishedAtError" class="mt-2 text-sm text-red-600"> {{ publishedAtError }} </p>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

<!--                        <div class="sm:col-span-1">-->
<!--                          <span class="title text-gray-400">Длительность</span>-->
<!--                          <VueDatePicker-->
<!--                              :disabled="!isNewsEdit"-->
<!--                              class="mt-2"-->
<!--                              v-model="duration"-->
<!--                              locale="ru"-->
<!--                              model-type="HH:mm"-->
<!--                              :format="'HH:mm'"-->
<!--                              auto-apply-->
<!--                              :enable-time-picker="true"-->
<!--                              time-picker-->
<!--                          />-->
<!--                          <p v-if="durationError" class="mt-2 text-sm text-red-600">-->
<!--                            {{ durationError }} </p>-->
<!--                        </div>-->

                        <div>
                          <input-text
                              :disabled="!isNewsEdit"
                              v-model="quotaPerOrder"
                              label="Квота на количество билетов в одном заказе"/>
                          <p v-if="quotaPerOrderError" class="mt-2 text-sm text-red-600"> {{ quotaPerOrderError }} </p>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div class="grid gap-4 mb-4">
                          <div
                              v-if="typeof image !== 'string' || !image?.length"
                              class="sm:col-span-2">
                            <h2 class="mb-2" style="text-align: center">Загрузить изображение *</h2>
                            <drop-file
                                v-model="image"
                                :type="imageTypes"
                                :single="true"
                            />
                            <p v-if="imageError" class="mt-2 text-sm text-red-600"> {{ imageError }} </p>
                          </div>
                          <div v-else>
                            <div class="image-content"
                                 style="text-align: center; width: 250px; margin: auto; padding-top: 60px; padding-bottom: 25px;">
                              <img
                                  style="width: 250px; height: auto; object-fit: cover"
                                  :src="image"
                                  alt="">
                              <button
                                  :disabled="!isNewsEdit"
                                  @click="onHandleDeleteImage"
                                  class="px-7 mr-5 mt-5 py-3 rounded-md text-white bg-red-500 font-medium hover:bg-red-400">
                                Удалить
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="authorImageTitleNameRu"
                                label="Наименование автора изображения (RU) *"/>
                            <p v-if="authorImageTitleNameRuError" class="mt-2 text-sm text-red-600"> {{ authorImageTitleNameRuError }} </p>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4">
                          <div class="sm:col-span-2">
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="authorImageTitleNameEn"
                                :label="'Наименование автора изображения (EN) ' + requiredValue"/>
                            <p v-if="authorImageTitleNameEnError" class="mt-2 text-sm text-red-600"> {{ authorImageTitleNameEnError }} </p>
                          </div>
                        </div>

                        <hr class="mb-4 mb-2">

                        <div>
                          <input-text
                              :disabled="!isNewsEdit"
                              v-model="externalLinkAccreditation"
                              label="Внешняя ссылка для аккредитации /авторизации"/>
                        </div>

                        <div class="sm:col-span-1">
                          <div>
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="schedulePeriodTimeRu"
                                label="Расписание (RU)"/>
                          </div>
                          <div>
                            <input-text
                                :disabled="!isNewsEdit"
                                v-model="schedulePeriodTimeEn"
                                label="Расписание (EN)"/>
                          </div>
                        </div>

                        <hr class="mb-4 mb-9 mt-10">

                        <div v-if="checkRole(['admin', 'manager'])">
                          <div
                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-44"
                                type="checkbox"
                                v-model="isSlider"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-44" class="font-medium text-basic-gray dark:text-gray-300">
                                Программа / Слайдер
                              </label>
                              <p
                                  id="helper-checkbox-text-44"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isSlider ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div
                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-55"
                                type="checkbox"
                                v-model="isMainEvent"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-55" class="font-medium text-basic-gray dark:text-gray-300">
                                Программа / Главные события
                              </label>
                              <p
                                  id="helper-checkbox-text-55"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isMainEvent ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div
                              class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-66"
                                type="checkbox"
                                v-model="isRecommend"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-66" class="font-medium text-basic-gray dark:text-gray-300">
                                Программа / Рекомендуем
                              </label>
                              <p
                                  id="helper-checkbox-text-66"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isRecommend ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-4"
                                type="checkbox"
                                v-model="isActiveRu"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-4" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (RU)
                              </label>
                              <p
                                  id="helper-checkbox-text-4"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveRu ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="flex items-center p-3 mb-7 pl-4 border border-gray-200 rounded dark:border-gray-700">
                            <input
                                id="bordered-checkbox-5"
                                type="checkbox"
                                v-model="isActiveEn"
                                name="bordered-checkbox"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <div class="ml-2 text-sm">
                              <label for="bordered-checkbox-5" class="font-medium text-basic-gray dark:text-gray-300">
                                Активность для (EN)
                              </label>
                              <p
                                  id="helper-checkbox-text-5"
                                  class="text-xs font-normal text-gray-500 dark:text-gray-300">
                                {{
                                  isActiveEn ? 'Активна (Да)' : 'Не активна (Нет)'
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSliderEmpty || isErrorValSlider"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{messageSliderEmpty || 'Ошибки в слайдере'}}
                    </div>
                  </div>

                  <div
                      style="padding: 5px 15px; margin-top: 40px; color: red; text-align: center;"
                      v-if="messageSessionEmpty || isErrorValSession"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      {{messageSessionEmpty || 'Ошибки в сеансах'}}
                    </div>
                  </div>

                  <div
                      v-if="isErrorVal"
                      style="padding: 5px 15px; margin-top: 40px; color: red;"
                      class="alert-message-error">
                    <div
                        style="padding: 5px 15px; background: #ffebef; border-radius: 5px;"
                        class="alert-message-content">
                      <ul style="list-style: initial; padding: 15px;">
                        <li v-if="nameRuError"><span style="font-weight: bold">Наименование (RU):</span> {{nameRuError}}</li>
                        <li v-if="nameEnError"><span style="font-weight: bold">Наименование (EN):</span> {{nameEnError}}</li>
                        <li v-if="shortDescriptionRuError"><span style="font-weight: bold">Краткое описание (RU):</span> {{shortDescriptionRuError}}</li>
                        <li v-if="shortDescriptionEnError"><span style="font-weight: bold">Краткое описание (EN):</span> {{shortDescriptionEnError}}</li>
                        <li v-if="descriptionRuError"><span style="font-weight: bold">Подробное описание (RU):</span> {{descriptionRuError}}</li>
                        <li v-if="descriptionEnError"><span style="font-weight: bold">Подробное описание (EN):</span> {{descriptionEnError}}</li>
                        <li v-if="selectedCategoryError"><span style="font-weight: bold">Категория мероприятия:</span> {{selectedCategoryError}}</li>
                        <li v-if="selectedOrganizationError"><span style="font-weight: bold">Организатор мероприятия:</span> {{selectedOrganizationError}}</li>
                        <li v-if="selectedAgeLimitError"><span style="font-weight: bold">Ограничение по возрасту:</span> {{selectedAgeLimitError}}</li>
                        <li v-if="selectedTypeError"><span style="font-weight: bold">Тип мероприятия:</span> {{selectedTypeError}}</li>
                        <li v-if="selectedPavilionError"><span style="font-weight: bold">Павильон:</span> {{selectedPavilionError}}</li>
                        <li v-if="selectedGroupError"><span style="font-weight: bold">Группы:</span> {{selectedGroupError}}</li>
                        <li v-if="publishedAtError"><span style="font-weight: bold">Дата публикации:</span> {{publishedAtError}}</li>
                        <li v-if="imageError"><span style="font-weight: bold">Изображение:</span> {{imageError}}</li>
                        <li v-if="authorImageTitleNameRuError"><span style="font-weight: bold">Наименование автора изображения (RU):</span> {{authorImageTitleNameRuError}}</li>
                        <li v-if="authorImageTitleNameEnError"><span style="font-weight: bold">Наименование автора изображения (EN):</span> {{authorImageTitleNameEnError}}</li>
                      </ul>
                    </div>
                  </div>

                  <div class="p-7 flex justify-between">
                    <div>
                      <div class="flex flex-wrap">
                        <div
                            v-for="process in statusProcess"
                            class="flex items-center mb-4">
                          <label @click.prevent="onHandleClickUpdate(process.id)"
                                 :class="['bg-status-' + (process.id === 14 ? 6 : process.id)]"
                                 class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80">
                            <span>{{ process.name }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            <div class="col-span-2 mb-4 xl:mb-2">
              <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
                <div class="flex justify-between items-center p-7 border-b border-gray-200">
                  <div class="text-3xl font-medium">
                    {{ $t('document.remarksTitle') }}
                  </div>
                </div>
                <div class="p-7 pt-4">
                  <div class="flex-1 justify-between flex flex-col h-[50vh]">
                    <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                      <div class="chat-message" v-for="(message, index) in messages">
                        <div class="flex items-end" :class="{'justify-end': !message.isAnswer}">
                          <div style="position: relative" class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                            <div>
                              <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.isAnswer, 'bg-blue-600 text-white': !message.isAnswer}">
                                <div class="text-xs">
                                  {{ message.user.name }}
                                </div>
                                <div class="text-xs">
                                  {{ message.createdAt }}
                                </div>
                                <div class="text-sm">
                                  {{ message.name }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                      <div class="relative flex">
                          <textarea
                              :placeholder="$t('chat.write_message')"
                              v-model="messageData"
                              rows="5"
                              class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                          ></textarea>
                      </div>
                      <div v-if="checkRole(['admin', 'manager', 'content_manager'])" class="items-center mt-2">
                        <button type="button"
                                @click.prevent="onHandleClickSendMessage"
                                class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                          <template v-if="!loadDataMessage">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                          </template>
                          <template v-else>
                            <svg aria-hidden="true" class="h-6 w-6 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </div>
</template>

<script setup>
import {checkRole} from "../../../../utilits/functions.js";
import Spinner from "../../../flowbite/spinner.vue";
import Modal from "../../../flowbite/modal.vue";
import {ref} from "vue";
import InputText from "../../../flowbite/form/inputText.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../../../flowbite/form/inputTextarea.vue";
import DropFile from "../../../flowbite/form/dropFile.vue";
import Multiselect from '@vueform/multiselect'
import {useRoute, useRouter} from 'vue-router';
import moment from "moment";
import {serviceEventCategoryList} from "../../../../service/lists/serviceListEventCategory.js";
import {serviceEventOrganizationList} from "../../../../service/lists/serviceListEventOrganization.js";
import {serviceAgeLimitList} from "../../../../service/lists/serviceListAgeLimit.js";
import {serviceEventTypeList} from "../../../../service/lists/serviceListEventType.js";
import {
  serviceContentEventFindById,
  serviceContentEventMessageCreate,
  serviceContentEventSessionDelete,
  serviceContentEventSliderDelete,
  serviceContentEventUpdate
} from "../../../../service/contents/news/serviceContentEvent.js";
import {serviceEventGroupList} from "../../../../service/lists/serviceListEventGroup.js";
import {servicePavilionList} from "../../../../service/lists/serviceListPavilion.js";

const route = useRoute();
const router = useRouter();

const isLoadingData = ref(true);
const saveMessageResponse = ref(null);
const messageData = ref(null);
const user = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null;
const loadDataMessage = ref(false);

const isShowModalSessionDelete = ref(false);
const helperSessionId = ref(null);

const isShowModalSliderDelete = ref(false);
const helperSliderId = ref(null);

const isNewsEdit = ref(false);
const eventId = ref(route.params?.id);
const status = ref(null);
const statusAction = ref(null);
const statusProcess = ref([]);
const createdAt = ref(null);
const updatedAt = ref(null);
const tabKey = ref('main');

const userCreated = ref(null);

const nameRu = ref('');
const nameRuError = ref('');
const nameEn = ref('');
const nameEnError = ref('');
const shortDescriptionRu = ref('');
const shortDescriptionRuError = ref('');
const shortDescriptionEn = ref('');
const shortDescriptionEnError = ref('');
const descriptionRu = ref('');
const descriptionRuError = ref('');
const descriptionEn = ref('');
const descriptionEnError = ref('');
const authorImageTitleNameRu = ref('');
const authorImageTitleNameRuError = ref('');
const authorImageTitleNameEn = ref('');
const authorImageTitleNameEnError = ref('');
const publishedAt = ref(null);
const publishedAtError = ref(null);
const isActiveRu = ref(true);
const isActiveEn = ref(true);
const selectedCategories = ref([]);
const selectedCategoryError = ref('');
const selectedOrganizations = ref([]);
const selectedOrganizationError = ref('');
const selectedAgeLimits = ref([]);
const selectedAgeLimitError = ref('');
const selectedTypes = ref([]);
const selectedTypeError = ref('');
const participant = ref(null);
const sessions = ref([]);
const pavilions = ref([]);
const selectedPavilions = ref([]);
const selectedPavilionError = ref('');
const groups = ref([]);
const selectedGroups = ref([]);
const selectedGroupError = ref('');
const duration = ref('');
const durationError = ref('');
const quotaPerOrder = ref(null);
const quotaPerOrderError = ref(null);
const sliders = ref([]);
const isSlider = ref(false);
const isMainEvent = ref(false);
const isRecommend = ref(false);
const externalLinkAccreditation = ref('');
const schedulePeriodTimeRu = ref('');
const schedulePeriodTimeEn = ref('');

const messageSliderEmpty = ref('');
const messageSessionEmpty = ref('');
const isErrorVal = ref(false);
const isErrorValSession = ref(false);
const isErrorValSlider = ref(false);

const messages = ref([]);

const categories = ref([]);
const organizations = ref([]);
const ageLimits = ref([]);
const types = ref([]);
// requiredValue - нужно для того, чтобы выставить (*) где обязательность заполнение для администрации
const requiredValue = checkRole(['admin', 'manager', 'content_manager']) ? '*' : '';

const image = ref(null);
const imageError = ref('');
const imageTypes = {
  extensions: ["png", "jpeg", 'jpg'],
  max_filesize: 10,
};

const onHandleClickTabKey = (key) => {
  tabKey.value = key;
}

const writeResponseData = (data) => {
  status.value = data.status;

  statusAction.value = data.statusProcess;
  statusProcess.value = [];
  isNewsEdit.value = false;

  if (checkRole(['admin', 'manager'])) {
    statusProcess.value = data.statusProcess?.process?.org || [];
  }
  if (checkRole(['admin', 'content_manager'])) {
    if (status.value === 6)
      statusProcess.value.push({id: 14, name: 'Опубликовать'});
  }
  if (checkRole(['content_exhibitor'])) {
    statusProcess.value = data.statusProcess?.process?.exp || [];
  }

  if (checkRole(['admin', 'manager', 'content_manager'])) {
    isNewsEdit.value = true;
  } else if (checkRole(['content_exhibitor', 'exhibitor']) && (data.status === 1 || data.status === 2 || data.status === 7)) {
    isNewsEdit.value = true;
  }

  messageData.value = '';

  userCreated.value = data.user;
  nameRu.value = data.nameRu;
  nameEn.value = data.nameEn;
  shortDescriptionRu.value = data.shortDescriptionRu;
  shortDescriptionEn.value = data.shortDescriptionEn;
  descriptionRu.value = data.descriptionRu;
  descriptionEn.value = data.descriptionEn;
  authorImageTitleNameRu.value = data.authorRu;
  authorImageTitleNameEn.value = data.authorEn;
  image.value = data.image;
  selectedCategories.value = data?.category?.id ? [data?.category?.id] : [];
  selectedOrganizations.value = data?.organization?.id ? [data?.organization?.id] : [];
  selectedAgeLimits.value = data?.ageLimit?.id ? [data?.ageLimit?.id] : [];
  selectedTypes.value = data?.type?.id ? [data?.type?.id] : [];
  selectedPavilions.value = data?.pavilion?.id ? [data?.pavilion?.id] : [];
  selectedGroups.value = data?.groups ? data?.groups?.map(item => item.id) : [];
  duration.value = data.duration;
  quotaPerOrder.value = data.quotaPerOrder;
  publishedAt.value = data?.publishedAt;
  isActiveRu.value = data?.activeRu;
  isActiveEn.value = data?.activeEn;
  createdAt.value = data.createdAt;
  updatedAt.value = data.updatedAt;
  participant.value = data.participant;
  messages.value = data.messages || [];
  sliders.value = data.sliders || [];
  isSlider.value = data.isSlider;
  isMainEvent.value = data.isMainEvent;
  isRecommend.value = data.isRecommend;
  externalLinkAccreditation.value = data.externalLinkAccreditation;
  schedulePeriodTimeRu.value = data.schedulePeriodTimeRu;
  schedulePeriodTimeEn.value = data.schedulePeriodTimeEn;

  const items = data.sessions || [];
  sessions.value = [];

  if (items?.length) {
    items.map(session => {
        sessions.value.push({
          id: session.id,
          name: session.name,
          nameError: '',
          dateStart: session.dateStart,
          dateStartError: '',
          timeStart: session.timeStart,
          timeStartError: '',
          timeEnd: session.timeEnd,
          timeEndError: '',
          datePlanOpen: session.datePlanOpen,
          isOpenRegistration: session.isOpenRegistration,
          isActive: session.isActive,
        });
    });
  }

  sliders.value.map(item => {
    item.imageError = '';
    item.authorRuError = '';
    item.authorEnError = '';
  });
}

const onHandleDeleteImage = () => {
  image.value = null;
}

if (eventId.value) {
  Promise.all([serviceEventCategoryList(), serviceEventOrganizationList(), serviceAgeLimitList(), serviceEventTypeList(), serviceContentEventFindById(eventId.value), serviceEventGroupList(), servicePavilionList()])
      .then(([responseEventCategory, responseEventOrganization, responseAgeLimit, responseEventType, responseEvent, responseEventGroup, responsePavilion]) => {
        isLoadingData.value = false;

        categories.value = responseEventCategory.data || [];
        organizations.value = responseEventOrganization.data || [];
        ageLimits.value = responseAgeLimit.data || [];
        types.value = responseEventType.data || [];
        groups.value = responseEventGroup.data || [];
        pavilions.value = responsePavilion.data || [];

        const data = responseEvent.data;
        if (data) {
          writeResponseData(data);
        }
      })
      .catch(error => {
        console.error('Ошибка при выполнении запросов:', error);
      });
}

const onHandleClickUpdate = (newStatus) => {
  const isValExhibitor = validateExhibitor();

  let isValAdministrationAgreed = null;
  if (!checkRole(['content_exhibitor']) && (newStatus === 6 || newStatus === 14)) {
    isValAdministrationAgreed = validateAdministrationAgreed();
  }

  const isValSession = validateSession();
  const isValSlider = validateSlider();

  isErrorValSlider.value = isValSlider;
  isErrorValSession.value = isValSession;

  isErrorVal.value = isValExhibitor || isValAdministrationAgreed;

  if (isValExhibitor || isValAdministrationAgreed || isValSlider || (sessions.value.length && isValSession))
    return null;

  const params = {
    status: newStatus,
    nameRu: nameRu.value,
    nameEn: nameEn.value,
    shortDescriptionRu: shortDescriptionRu.value,
    shortDescriptionEn: shortDescriptionEn.value,
    descriptionRu: descriptionRu.value,
    descriptionEn: descriptionEn.value,
    authorImageTitleNameRu: authorImageTitleNameRu.value,
    authorImageTitleNameEn: authorImageTitleNameEn.value,
    categoryId: selectedCategories.value.length ? selectedCategories.value[0] : null,
    organizationId: selectedOrganizations.value.length ? selectedOrganizations.value[0] : null,
    ageLimitId: selectedAgeLimits.value.length ? selectedAgeLimits.value[0] : null,
    typeId: selectedTypes.value.length ? selectedTypes.value[0] : null,
    pavilionId: selectedPavilions.value.length ? selectedPavilions.value[0] : null,
    groups: selectedGroups.value.length ? selectedGroups.value : [],
    duration: duration.value,
    quotaPerOrder: quotaPerOrder.value,
    publishedAt: publishedAt.value,
    image: image.value.length ? image.value[0] : null,
    activeRu: isActiveRu.value,
    activeEn: isActiveEn.value,
    messages: messageData.value ? [messageData.value] : [],
    sessions: sessions.value,
    sliders: sliders.value,
    isSlider: isSlider.value,
    isMainEvent: isMainEvent.value,
    isRecommend: isRecommend.value,
    externalLinkAccreditation: externalLinkAccreditation.value,
    schedulePeriodTimeRu: schedulePeriodTimeRu.value,
    schedulePeriodTimeEn: schedulePeriodTimeEn.value,
  };

  serviceContentEventUpdate(eventId.value, params).then(response => {
    const status = response?.success;
    const message = status ? 'Успешно обновлено' : 'Ошибка, данные не были обновлены';

    saveMessageResponse.value = {
      message,
      status,
    };

    setTimeout(() => {
      saveMessageResponse.value = null;
    }, 3000);

    if (response.success) {
      const news = response.data;
      writeResponseData(news);
    } else {
      console.error('log');
    }
  });
}

const onHandleClickSendMessage = () => {
  loadDataMessage.value = true;
  serviceContentEventMessageCreate(eventId.value, {message: messageData.value}).then(response => {
    loadDataMessage.value = false;

    if (response.success) {
      messages.value.push({
        name: messageData.value,
        createdAt: moment().format('DD.MM.YYYY HH:mm'),
        user,
        isAnswer: !!checkRole(['content-exhibitor']),
      });

      messageData.value = null;
    }
  });
}

const validateExhibitor = () => {
  let isError = false;

  const valueName = nameRu.value?.trim();
  nameRuError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameRuError.value)
    isError = true;

  const valueShortDescriptionRu = shortDescriptionRu.value?.trim();
  shortDescriptionRuError.value = valueShortDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionRuError.value)
    isError = true;

  const valueDescriptionRu = descriptionRu.value?.trim();
  descriptionRuError.value = valueDescriptionRu?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionRuError.value)
    isError = true;

  selectedCategoryError.value = selectedCategories.value?.length ? '' : 'Вы не выбрали категорию';
  if (selectedCategoryError.value)
    isError = true;

  selectedOrganizationError.value = selectedOrganizations.value?.length ? '' : 'Вы не выбрали организатора';
  if (selectedOrganizationError.value)
    isError = true;

  selectedAgeLimitError.value = selectedAgeLimits.value?.length ? '' : 'Вы не выбрали возрастное ограничение';
  if (selectedAgeLimitError.value)
    isError = true;

  selectedTypeError.value = selectedTypes.value?.length ? '' : 'Вы не выбрали тип мероприятия';
  if (selectedTypeError.value)
    isError = true;

  publishedAtError.value = publishedAt.value ? '' : 'Поле не должно быть пустым';
  if (publishedAtError.value)
    isError = true;

  const valueAuthorImageTitleNameRu = authorImageTitleNameRu.value?.trim();
  authorImageTitleNameRuError.value = valueAuthorImageTitleNameRu?.length ? '' : 'Поле не должно быть пустым';
  if (authorImageTitleNameRuError.value)
    isError = true;

  imageError.value = image.value ? '' : 'Вы не выбрали файл';
  if (imageError.value)
    isError = true;

  return isError;
}

const validateAdministrationAgreed = () => {
  let isError = false;

  const valueName = nameEn.value?.trim();
  nameEnError.value = valueName?.length ? '' : 'Поле не должно быть пустым';
  if (nameEnError.value)
    isError = true;

  const valueShortDescriptionEn = shortDescriptionEn.value?.trim();
  shortDescriptionEnError.value = valueShortDescriptionEn?.length ? '' : 'Поле не должно быть пустым';
  if (shortDescriptionEnError.value)
    isError = true;

  const valueDescriptionEn = descriptionEn.value?.trim();
  descriptionEnError.value = valueDescriptionEn?.length ? '' : 'Поле не должно быть пустым';
  if (descriptionEnError.value)
    isError = true;

  const valueAuthorImageTitleNameEn = authorImageTitleNameEn.value?.trim();
  authorImageTitleNameEnError.value = valueAuthorImageTitleNameEn?.length ? '' : 'Поле не должно быть пустым';
  if (authorImageTitleNameEnError.value)
    isError = true;

  return isError;
}

const validateSession = () => {
  let isError = false;

  if (!sessions.value?.length) {
    messageSessionEmpty.value = 'Добавьте хотя-бы одну сессию';
    return true;
  }
  else messageSessionEmpty.value = '';

  sessions.value?.map(session => {
    session.nameError = !session.name?.length ? 'Обязательна для заполнения' : '';
    session.dateStartError = !session.dateStart ? 'Обязательна для заполнения' : '';
    session.timeStartError = !session.timeStart ? 'Обязательна для заполнения' : '';
    session.timeEndError = !session.timeEnd ? 'Обязательна для заполнения' : '';

    if (session?.isGenerate) {
      session.dateEndError = !session.dateEnd ? 'Обязательна для заполнения' : '';

      const countWeek = session.weeks?.filter(week => week.value === false);
      if (countWeek === 7) {
        session.weekError = 'Вы не выбрали не одного дня недели';

        isError = true
      }
      else session.weekError = '';
    }

    const timeError = validateSessionDatetime(session.timeStart, session.timeEnd);

    if (!session.timeStartError?.length) {
      session.timeStartError = timeError.timeStartError;
    }
    if (!session.timeEndError?.length) {
      session.timeEndError = timeError.timeEndError;
    }

    if (session.nameError || session.dateStartError || session.timeStartError || session.timeEndError || session.dateEndError)
      isError = true;
  });

  return isError;
}

const validateSlider = () => {
  let isError = false;

  sliders.value.map(item => {
    let image = item.image;
    if (Array.isArray(image)) {
      item.image = item.image[0];
    }
  });

  if (!sliders.value?.length) {
    messageSliderEmpty.value = 'Добавьте хотя-бы один слайд';
    return true;
  }
  else messageSliderEmpty.value = '';

  sliders.value?.map(slider => {
    slider.imageError = !slider.image ? 'Вы не выбрали изображение' : '';

    if (slider.imageError)
      isError = true;

    slider.authorRuError = slider.authorRu?.length ? '' : 'Обязательно для заполнения';
    if (slider.authorRuError?.length)
      isError = true;
  });

  return isError;
}

const onHandleClickSessionCreate = () => {
  sessions.value.push({
    name: '',
    nameError: '',
    dateStart: null,
    dateStartError: '',
    timeStart: null,
    timeStartError: '',
    timeEnd: null,
    timeEndError: null,
    datePlanOpen: null,
    isOpenRegistration: true,
    isActive: true,
  });
}

const onHandleClickSessionGenerate = () => {
  sessions.value.push({
    name: '',
    weeks: [
      {
        name: 'ПН',
        value: false,
      },
      {
        name: 'ВТ',
        value: false,
      },
      {
        name: 'СР',
        value: false,
      },
      {
        name: 'ЧТ',
        value: false,
      },
      {
        name: 'СБ',
        value: false,
      },
      {
        name: 'ВС',
        value: false,
      },
    ],
    isGenerate: true,
    nameError: '',
    dateStart: null,
    dateStartError: '',
    dateEnd: null,
    dateEndError: null,
    timeStart: null,
    timeStartError: '',
    timeEnd: null,
    timeEndError: null,
    datePlanOpen: '',
    isOpenRegistration: true,
    isActive: true,
  });
}

const onHandleClickSliderCreate = () => {
  sliders.value.push({
    image: null,
    imageError: null,
    authorRu: '',
    authorRuError: '',
    authorEn: '',
    authorEnError: '',
  });
}

const onHandleSliderDelete = () => {
  if (!eventId.value || !helperSliderId.value)
    return null;

  serviceContentEventSliderDelete(eventId.value, helperSliderId.value).then(response => {
    if (response.code !== 200)
      return null;

    sliders.value = sliders.value.filter(item => item?.id !== helperSliderId.value);

    helperSliderId.value = null;
    isShowModalSliderDelete.value = false;
  });
}

const onHandleDeleteActionModelSlider = (index, id) => {
  if (!id) {
    sliders.value.splice(index, 1);
  }
  else {
    helperSliderId.value = id;
    isShowModalSliderDelete.value = true;
  }
}

const onHandleModalSliderClose = () => {
  helperSliderId.value = null;
  isShowModalSliderDelete.value = false;
}

const onHandleDeleteActionModelSession = (index, id) => {
  if (!id) {
    sessions.value.splice(index, 1);
  }
  else {
    isShowModalSessionDelete.value = true;
    helperSessionId.value = id;
  }
}

const onHandleSessionDelete = () => {
  if (!helperSessionId.value && eventId.value)
    return null;

  serviceContentEventSessionDelete(eventId.value, helperSessionId.value).then(response => {
    if (response.code !== 200)
      return null;

    sessions.value = sessions.value.filter(item => item?.id !== helperSessionId.value);

    helperSessionId.value = null;
    isShowModalSessionDelete.value = false;
  });
}

const onHandleModalSessionClose = () => {
  helperSessionId.value = null;
  isShowModalSessionDelete.value = false;
}

const validateSessionDatetime = (timeStart, timeEnd) => {
  if (timeStart && timeEnd) {
    if (timeStart >= timeEnd) {
      return {timeStartError: 'Время начала не может быть позже даты окончания', timeEndError: 'Время окончания не может быть раньше даты начала'};
    } else {
      return {timeStartError: '', timeEndError: ''};
    }
  }

  return {timeStartError: '', timeEndError: ''};
}

const validateSessionTime = (index) => {
  const timeStart = sessions.value[index].timeStart;
  const timeEnd = sessions.value[index].timeEnd;

  const error = validateSessionDatetime(timeStart, timeEnd);

  sessions.value[index].timeStartError = error.timeStartError;
  sessions.value[index].timeEndError = error.timeEndError;
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.tabs {
  margin-bottom: 35px;
}

.tabs .tab.active {
  background: #c6d6f4;
}

.tabs .tab {
  background: #f0f8ff;
  transition: all .3s;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.tabs .tab:hover {
  cursor: pointer;
  transition: all .3s;
  background: #d0e9ff;
}

.sessions .session-item {
  padding: 15px 15px 0 15px;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.session-add {
  margin-top: 15px;
}

.session-add .session-btn {
  text-align: center;
  padding: 15px;
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #c6d6f4;
  transition: all .3s;
}

.session-add .session-btn:hover {
  background: #edf7ff;
  border-color: #92b8ff;
  transition: all .3s;
}

.sessions .session-content-week .session-week {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.sessions .session-content-week .session-week .session-week-item .week {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sessions .session-content-week .session-week .session-week-item .week label {
  width: 100%;
  margin-left: 0;
  text-align: center;
  margin-top: 5px;
}
</style>
