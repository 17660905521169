<template>
  <div class="grid grid-cols-1 px-7 pt-7">
    <div class="col-span-full mb-4 xl:mb-2">
      <div class="mb-5 bg-white rounded-lg border border-gray-200">

        <div class="flex justify-between items-center mb-4 p-7 border-b border-gray-200">
          <div>
            <h1 class="text-3xl font-medium">Справочник "Категории новостей"</h1>
          </div>
        </div>

        <div class="flex w-full justify-between">
          <div class="flex-col p-7 w-1/2">
            <form @submit.prevent="searchEntity()" action="#" method="GET">
              <label for="top-bar-search" class="sr-only">{{ $t('common.search') }}</label>
              <div class="relative w-full">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="search"
                       type="text" id="top-bar-search"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
                       :placeholder="$t('common.search')">
              </div>
            </form>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="overflow-x-auto">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden shadow">
                <spinner v-if="isLoadingData"/>
                <table v-if="!isLoadingData" class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-gray-100">
                  <tr>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('directoryDocuments.name') }}
                    </th>
                    <th scope="col" class="p-4 text-left font-light text-gray-400">
                      {{ $t('directoryDocuments.sort') }}
                    </th>
                    <th scope="col" class=""></th>
                  </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">

                  <tr v-for="item of items" :key="item.id" :class="{'bg-neutral-100 opacity-50': item.deletedAt}" class="hover:bg-gray-100">
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">
                        {{ item.name }}
                      </p>
                    </td>
                    <td class="p-4">
                      <p class="text-base font-medium text-gray-900">{{ item.sort }}</p>
                    </td>
                    <td class="text-right">
                      <router-link :to="{name: 'NewsCategoryShow', params: {id: item.id}}" class="py-2 px-3 text-sm font-medium text-center">
                        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                        </svg>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h3 v-if="!isLoadingData && !pagination?.total" class="text-xl text-center p-7">{{ $t('common.notFound') }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Spinner from "../../../../flowbite/spinner.vue";
import {ref, watch} from "vue";
import {serviceNewsCategoryList} from "../../../../../service/lists/serviceListNewsCategory.js";

const isLoadingData = ref(true);

const search = ref('');
const items = ref([]);
const pagination = ref(null);

serviceNewsCategoryList().then(response => {
  isLoadingData.value = false;

  items.value = response?.data || [];
  pagination.value = response.pagination;
});

</script>
