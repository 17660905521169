import {getToken, handleAsync} from "../handleAsync.js";

const url = import.meta.env.VITE_BASE_URL_API + '/v1';

export async function serviceSelectedUserContours(id) {
    const queryId = id ? '?id=' + id : '';
    const token = await getToken();

    return await handleAsync(fetch(`${url}/users/contours` + queryId , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceContourExhibitor(id) {
    const token = await getToken();

    return await handleAsync(fetch(`${url}/contours/${id}/exhibitors` , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

export async function serviceSelectedUserContoursFindId(id, userId) {
    const queryId = userId ? '?userId=' + userId : '';

    const token = await getToken();

    return await handleAsync(fetch(`${url}/users/contours/${id}` + queryId , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    }));
}

function getQueryParams(id) {
    return id ? `?id=${id}` : '';
}
